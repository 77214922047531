import React from "react";

function CenteredTitle({ title, subtitle }) {
  return (
    <div className="w-100 text-center my-3">
      <h1>{title}</h1>
      <h5 className="text-muted fw-normal fst-italic">
        {subtitle ? subtitle : <>&nbsp;</>}
      </h5>
    </div>
  );
}

export default CenteredTitle;
