import Cookies from "universal-cookie";
import { getMsalInstance } from "../components/UI/MicrosoftAuth/msalService";
const cookies = new Cookies();

export const logoutAPI = async () => {
  if (cookies.get("user")) {
    cookies.remove("user");
    cookies.remove("expiresIn");
    localStorage.removeItem("shortPassword")
    sessionStorage.removeItem('userAuthenticated')
  } else {
    localStorage.removeItem("authMethod");
    localStorage.removeItem("user_oauth");
    localStorage.removeItem('REACT_APP_OAUTH_SCOPES')
    sessionStorage.removeItem('userAuthenticated')
    cookies.remove("userOAuth")
  }
};
