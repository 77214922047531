import AppRoutes from "./components/Menu/AppRoutes";
import Layout from "./components/Layout";
import { AuthProvider } from "./contexts/AuthContext";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import SharepointCookieBanner from "./components/SharepointCookieBanner/SharepointCookieBanner";
import { CookieConsentProvider } from "./contexts/CookieConsentContext";

const App = () => {
  return (
    <AuthProvider>
      <CookieConsentProvider>
        <Router>
          <Layout>
            <SharepointCookieBanner />
            <AppRoutes />
          </Layout>
        </Router>
      </CookieConsentProvider>
    </AuthProvider>
  );
};

export default App;
