import React from "react";
import Content from "./Content";
import InfoPage from "../InfoPages";

function index() {
  return (
    <InfoPage title="How Pete works">
      <Content />
    </InfoPage>
  );
}

export default index;
