import React from "react";
import style from "./index.module.css";

function ConfluenceURL() {
  return (
    <div className="mt-4">
      <h6>Confluence URL</h6>
      <ul>
        <li>
          Open your Confluence space and copy the URL from the beginning up to
          the /wiki/…
        </li>
        <li>
          Example:{" "}
          <span className="text-primary">https://iwdg.atlassian.net</span>
        </li>
        <li>
          Add the next text to complete the above URL
          <span className="text-primary">/wiki/rest/api/content/</span>
        </li>
        <li>
          The final URL should look like this:
          <snap className="text-primary">
            https://iwdg.atlassian.net/wiki/rest/api/content/
          </snap>
        </li>
      </ul>

      <img
        src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceURL.png"
        alt="Confluence URL"
        className={style["info-page-image"]}
      />
    </div>
  );
}

export default ConfluenceURL;
