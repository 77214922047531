import React from "react";
import authContainerStyles from "./Auth.module.css";
import FooterLogo from '../../components/UI/FooterLogo/FooterLogo';
import MicrosoftAuthentication from '../../components/UI/MicrosoftAuth/MicrosoftAuth'

const AuthContainer = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        
        <div className="col-md-6 bg-light">
          <div className={`${authContainerStyles.login} d-flex align-items-center py-5`}>
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-7 mx-auto">
                  {children}
                  <MicrosoftAuthentication/>
                </div>
              <FooterLogo/>
              </div>
            </div>
          </div>
        </div>

        <div className={`col-md-6 d-none d-md-flex ${authContainerStyles.bg_image}`}></div>
      </div>
    </div>
  );
};

export default AuthContainer;
