import React from "react";
import { Container, Table } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import { formatUrl, get, put } from "../../utils/api";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import Button from "../../components/Button";
import { useState } from "react";
import UserTableHeader from "./UserTableHeader.js";
import AddUserModal from "./AddUserModal";
import Modal from "../../components/Modal";

const Users = () => {
  const selectedOrganization = useSelectedOrganization();
  const [roles, setRoles] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userMicrosoftAuth, setUserMicrosoftAuth] = useState(false);
  const [userBasicAuth, setUserBasicAuth] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [checked, setChecked] = useState(false)
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState('Save')

  const callApi = async () => {
    const apiUrl = formatUrl(`users`, {
      organization: selectedOrganization,
    });
    try {
      const response = await get(apiUrl);
      if (response) {
        setResponseData(response.data);
        setSelectedCheckboxes(response.data.map(() => false));
        setRoles(response.data.map((user) => user.role));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization])


  const handleCheckboxChange = (index) => {
    setChecked(true)
    const newCheckboxes = [...selectedCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setSelectedCheckboxes(newCheckboxes);
    const allUnchecked = newCheckboxes.every(checkbox => !checkbox);
    if (allUnchecked) {
      setDeleteButtonDisabled(true);
    } else {
      setDeleteButtonDisabled(false);
    }
  };

  const onConfirmClick = async () => {
    const changedUsers = [];
    responseData.map((user, index) => {
      if (user.role !== roles[index] || selectedCheckboxes[index]) {
        const changedUser = { user: user.email, role: user.role };
        if (user.role !== roles[index]) {
          const role = roles[index] === "Admin user" ? 2 : 1;
          changedUser.role = role;
        }
        if (selectedCheckboxes[index]) {
          changedUser.deleted = true;
        }
        changedUsers.push(changedUser);
      }
    });

    if (changedUsers.length > 0) {
      const apiUrl = formatUrl("user-role", {
        organization: selectedOrganization,
      });
      const response = await put(apiUrl, changedUsers);
      if (response.status === 200) {
        toast.success("Changes applied successfully!");
        setResponseData((prevData) => prevData.filter((user) => !user.deleted));
        setShowModal(false);
        setChecked(false)
        setDeleteButtonDisabled(true)
        callApi();
      }
    }
  };

  const handleEditClick = async (item) => {
    const role = item.role === 2 ? "Admin user" : "Regular user"
    const basic_auth = item.basic_auth === 'null' ? 0 : item.basic_auth
    const microsoft_auth = item.microsoft_auth === null ? 0 : item.microsoft_auth
    setUserBasicAuth(basic_auth === 0 ? false : true)
    setUserMicrosoftAuth(microsoft_auth === 0 ? false : true)
    setUserRole(role)
    setUserEmail(item.email);
    setUserFirstName(item.firstName);
    setUserLastName(item.lastName);
    setIsEditUser(true);
    setIsModalOpen(true);
  };

  const handleSaveClick = async () => {
    setShowModal(true);
  };

  const handleAddUserClick = () => {
    setIsEditUser(false)
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="cancelButton" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
      <Button id="create-new-organization-summary" onClick={onConfirmClick}>
        Apply Changes
      </Button>
    </div>
  );

  return (
    <Container as={"main"} fluid className="px-5">
      <Table hover responsive className="mt-4" style={{ fontSize: "14px" }}>
        <UserTableHeader />
        <tbody>
          {responseData &&
            responseData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div style={{ marginTop: "10px" }}>{item.firstName}</div>
                  </td>
                  <td>
                    <div style={{ marginTop: "10px" }}>{item.lastName}</div>
                  </td>
                  <td>
                    <div style={{ marginTop: "10px" }}>{item.email}</div>
                  </td>
                  <td>
                    <div style={{ marginTop: "10px" }}>
                      <PencilSquare
                        id="edit-user"
                        onClick={() => handleEditClick(item)}
                      />
                    </div>
                  </td>
                  <td>
                    <div style={{ marginTop: "10px" }}>
                      <input
                        type="checkbox"
                        checked={selectedCheckboxes[index] || false}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-start mt-3">
        <Button type="submit" onClick={handleAddUserClick}>
          Add User
        </Button>
        <div className="d-flex">
          <Button type="submit" onClick={handleSaveClick} disabled={deleteButtonDisabled}>
            Delete
          </Button>
        </div>
      </div>
      <AddUserModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        isEditUser={isEditUser}
        organization={selectedOrganization}
        userEmail={userEmail}
        userFirstName={userFirstName}
        userLastName={userLastName}
        userRole={userRole}
        basicAuth={userBasicAuth}
        microsoftAuth={userMicrosoftAuth}
        setResponseData={setResponseData}
        responseData={responseData}
        setSelectedCheckboxes={setSelectedCheckboxes}
        setRoles={setRoles}
      />
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title=""
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>
          Are you sure you want to apply these changes to selected users?
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Users;
