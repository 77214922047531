// func below returns a function that takes a list of classes as an argument
// reduces it into a spaced string
export const classLister = styleObject => (classList) => {
  return classList.reduce((list, currentClass) => {
    let output = list;

    if (styleObject[currentClass]) {
      if (list) {
        output += ' '; // appends a space if list is not empty
      }

      output += styleObject[currentClass];
    }

    return output;
  }, '')
};
