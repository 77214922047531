import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import validator from "validator";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { formatUrl, get, post } from "../../utils/api";
import { toast } from "react-toastify";

function AddUserModal({
  isOpen,
  onClose,
  isEditUser,
  organization,
  userEmail,
  userFirstName,
  userLastName,
  userRole,
  basicAuth,
  microsoftAuth,
  setResponseData,
  responseData,
  setSelectedCheckboxes,
  setRoles
}) {
  const [userForm, setUserForm] = useState({
    email:  "",
    firstName: "",
    lastName: "",
    role: "Regular user",
    microsoft_auth: false,
    basic_auth: false,
  });
  const [emailError, setEmailError] = useState("");

  const callApi = async () => {
      const apiUrl = formatUrl(`users`, {
        organization: organization,
      });
      try {
        const response = await get(apiUrl);
        if (response) {
          setResponseData(response.data);
          setSelectedCheckboxes(response.data.map(() => false));
          setRoles(response.data.map((user) => user.role));
        }
      } catch (error) {
        toast.error(error.message);
      }
  };

  useEffect(() => {
    if (isEditUser) {
      callApi()
    }
  },[userForm])

  useEffect(() => {
    if(isEditUser && isOpen) {
      setUserForm({
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        role: userRole,
        microsoft_auth: microsoftAuth,
        basic_auth: basicAuth,
      });
    } else {
      setUserForm({
        email:  "",
        firstName: "",
        lastName: "",
        role: "Regular user",
        microsoft_auth: false,
        basic_auth: false,
      })
    }
  }, [
    isEditUser,
    isOpen,
    userEmail,
    userFirstName,
    userLastName,
    userRole,
    microsoftAuth,
    basicAuth,
  ]);

  const handleChange = (field, value) => {
    setUserForm((prev) => ({ ...prev, [field]: value }))
  };

  const handleSaveClick = async () => {
    const userRole = userForm.role === "Admin user" ? 2 : 1;
    const emailErrorMessage =
      !isEditUser && userForm.email.trim().length === 0
        ? "Please enter an email address"
        : !isEditUser && !validator.isEmail(userForm.email)
        ? "Enter a valid Email!"
        : "";

    if (emailErrorMessage) {
      setEmailError(emailErrorMessage);
    } else {
      try {
        const response = await post("user", {
          organization: organization,
          firstName: isEditUser ? userFirstName : userForm.firstName,
          lastName: isEditUser ? userLastName : userForm.lastName,
          email: isEditUser ? userEmail : userForm.email,
          role: userRole,
          microsoft_auth: userForm.microsoft_auth,
          basic_auth: userForm.basic_auth,
          userEdited: isEditUser,
        });

        if (response.status === 201) {
          const newUser = {
            firstName: userForm.firstName,
            lastName: userForm.lastName,
            email: userForm.email,
            role: userRole,
            microsoft_auth: userForm.microsoft_auth,
            basic_auth: userForm.basic_auth,
          };
          const foundUser = responseData.find(data => data.email === newUser.email)

          if (isEditUser) {
            const existingUserIndex = responseData.findIndex(
              (data) => data.email === userEmail
            );
            if (existingUserIndex !== -1) {
              const updatedUser = {
                ...responseData[existingUserIndex],
                firstName: userFirstName,
                lastName: userLastName,
                role: userRole,
                microsoft_auth: userForm.microsoft_auth,
                basic_auth: userForm.basic_auth,
              };
              const updatedData = [...responseData];
              updatedData[existingUserIndex] = updatedUser;
              setResponseData(updatedData);
            }
            toast.success(response.data)
          } else {
            if(!foundUser) {
              setResponseData((prevData) => [...prevData, newUser]);
              toast.success(response.data)
            } else {
              toast.error("User already exists")
            }
          }
          const user_Role = userRole === 2 ? "Admin user" : "Regular user";
          setUserForm({
            email: isEditUser ? userEmail : "",
            firstName: isEditUser ? userFirstName : "",
            lastName: isEditUser ? userLastName : "",
            microsoft_auth: isEditUser ? microsoftAuth : false,
            basic_auth: isEditUser ? basicAuth : false,
            role: isEditUser ? user_Role : ""
          })
          onClose();
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const canSave = () => {
    return (
      userForm.firstName &&
      userForm.lastName &&
      validator.isEmail(userForm.email) &&
      (userForm.microsoft_auth || userForm.basic_auth)
    )
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{isEditUser ? "Edit User" : "Add User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col style={{ maxWidth: "30%" }}>First name</Col>
          <Col className="text-start">
            <Input
              label="First Name"
              value={userForm.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
              disabled={isEditUser}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ maxWidth: "30%" }}>Last name</Col>
          <Col className="text-start">
            <Input
              label="Last Name"
              value={userForm.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
              disabled={isEditUser}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ maxWidth: "30%" }}>Email</Col>
          <Col className="text-start">
            <Input
              label="Email"
              type="email"
              value={userForm.email}
              onChange={(e) => handleChange("email", e.target.value)}
              disabled={isEditUser}
              error={emailError}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ maxWidth: "30%" }}>Role</Col>
          <Col className="text-start">
            <Select
              label="Role"
              value={userForm.role}
              onChange={(e) => handleChange("role", e.target.value)}
              options={["Regular user", "Admin user"]}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "10px", marginTop: "10px", maxWidth: "30%" }}>
            Authentication
          </Col>
          <Col className="text-start">
            <div style={{ marginTop: "10px" }}>
              <div className="microsoftAuth">
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="checkbox"
                    checked={userForm.microsoft_auth}
                    onChange={() =>
                      handleChange("microsoft_auth", !userForm.microsoft_auth)
                    }
                  />
                  Microsoft Auth
                </label>
              </div>
              <div className="basicAuth">
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="checkbox"
                    checked={userForm.basic_auth}
                    onChange={() =>
                      handleChange("basic_auth", !userForm.basic_auth)
                    }
                  />
                  Basic Auth
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSaveClick} disabled={!canSave()}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddUserModal;
