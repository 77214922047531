import React from "react";
import style from "./index.module.css";

function UserRegistration() {
  return (
    <div className="mt-3">
      <h4>User Registration</h4>
      <p>Each potential user may request registration and provide their:</p>

      <ul className={style["disc-list"]}>
        <li>Name</li>
        <li>Last name</li>
        <li>Organization</li>
        <li>Email </li>
        <li>Optional Notes</li>
      </ul>
      <p>
        This information is stored in a secure database and used solely for
        registration purposes. After confirmation by Pete’s team, users receive
        a welcome email with a temporary password, which must be changed on
        their first login.
      </p>
      <p>
        Ures may be also asked via email by Pete’s team to provide company
        Information:
      </p>
      <ul className={style["disc-list"]}>
        <li>Company name</li>
        <li>Company web address</li>
      </ul>
    </div>
  );
}

export default UserRegistration;
