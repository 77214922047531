import React from 'react'
import style from "./index.module.css";

const DataSharing = () => {
    return (
        <div className="mt-5">
            <h4>Data sharing</h4>
            <p>
                Pete is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:
            </p>
            <ul className={style["disc-list"]}>
                <li>
                    <strong>With service providers.</strong>
                    We may share your Personal Data with service providers that provide us with support services, such as secure website hosting, cloud storage, information technology maintenance, transaction monitoring, network infrastructure, security, fraud detections, customer support and analytics.
                </li>
                <li>
                    <strong>Pete’s subprocessors. </strong>
                    As part of the delivery of services to our Pete customers, we use third-party sub processors. The sub processors used to provide SnapLogic services are broken out by name of application and purpose of processing.                 </li>
                <li>
                    <strong>With professional business advisors. </strong>
                    We may share your Personal Data with our professional advisors who provide banking, legal, 	compliance, and other consulting services to complete required legal audits of our operations, or otherwise comply with our legal obligations.
                </li>
                <li>
                    <strong>As required by law.</strong>
                    We may disclose your information if we believe that the disclosure is required by law, if we believe that the disclosure is necessary to enforce our agreements or policies, in response to 	valid requests by public authorities (e.g., a court or a government agency), or if we believe that 	the disclosure will help us protect the rights, property, or safety of Pete or our Customers.
                </li>
                <li>
                    <strong>With your consent.</strong>
                    We may disclose your personal data for any purpose with your consent. For example, to other data controllers whose terms of service you consented to.
                </li>
            </ul>
        </div>
    )
}

export default DataSharing