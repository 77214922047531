import {
  get as getAxios,
  post as postAxios,
  put as putAxios,
  interceptors,
} from "axios";
import Cookies from "universal-cookie";
import { logoutAPI } from "../service/auth.service";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { getMsalInstance } from "../components/UI/MicrosoftAuth/msalService";

const cookies = new Cookies();

export const formatUrl = (path, params = {}) => {
  const paramsString = Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  if (paramsString.length > 0) {
    return `${path}?${paramsString}`;
  }

  return path;
};

export const loginOauth2Api = async (
  path,
  queryParameters = "",
  headers = {},
  auth = null
) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/${path}${queryParameters}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": process.env.REACT_APP_X_FUNCTIONS_KEY,
      ...headers,
    },
    auth,
  };

  return await fetch(url, requestOptions);
};

export const get = async (path, headers = {}, auth = null) => {
  return await getAxios(`${process.env.REACT_APP_API_BASE_URL}/${path}`, {
    headers: {
      ...headers,
      "x-functions-key": process.env.REACT_APP_X_FUNCTIONS_KEY,
    },
    auth,
  });
};

export const getBlob = async (path, headers = {}) => {
  const requestHeaders = new Headers();

  requestHeaders.append(
    "x-functions-key",
    process.env.REACT_APP_X_FUNCTIONS_KEY
  );

  Object.entries(headers).forEach((key, value) => headers.append(key, value));

  var requestOptions = {
    method: "GET",
    headers: requestHeaders,
  };

  return await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/${path}`,
    requestOptions
  )
    .then((response) => response.blob())
    .then((result) => result);
};

export const post = async (path, body, headers = {}, auth = null) => {
  return await postAxios(
    `${process.env.REACT_APP_API_BASE_URL}/${path}`,
    body,
    {
      headers: {
        ...headers,
        "x-functions-key": process.env.REACT_APP_X_FUNCTIONS_KEY,
      },
      auth,
    }
  );
};

export const put = async (path, body, headers = {}, auth = null) => {
  return await putAxios(`${process.env.REACT_APP_API_BASE_URL}/${path}`, body, {
    headers: {
      ...headers,
      "x-functions-key": process.env.REACT_APP_X_FUNCTIONS_KEY,
    },
    auth,
  });
};

export const loginApi = async (path, body, headers = {}, auth = null) => {
  return await postAxios(
    `${process.env.REACT_APP_API_BASE_URL}/${path}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        "x-functions-key": process.env.REACT_APP_X_FUNCTIONS_KEY,
      },
      auth,
    }
  );
};

interceptors.request.use(
  async (requestConfig) => {
    let token;
    const newRequestConfig = { ...requestConfig };
    const msalInstance = getMsalInstance();
    const userOauthToken = cookies.get('userOAuth');
    const authMethod = localStorage.getItem("authMethod");
    if (authMethod === "OAUTH2") {
      if (msalInstance) {
        const oauthScopes = localStorage.getItem('REACT_APP_OAUTH_SCOPES')
        const account = msalInstance?.getActiveAccount();
        const response = await msalInstance.acquireTokenSilent({
          scopes: [`${oauthScopes}`],
          account,
        });

        token = response.idToken;
        cookies.set('userOAuth', response.idToken);
        localStorage.setItem("user_oauth", response.account.username);
      } else {
        token = userOauthToken;
      }
    } else {
      const user = cookies.get("user");
      token = user?.token;
    }
    if (token) {
      newRequestConfig.headers.Authorization = `Bearer ${token}`;
    }
  
    return newRequestConfig;
  },
  (error) => Promise.reject(error)
);

interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401 &&
      (error.response.data.name === "Token is not valid" || error.response.data.name === "TokenExpiredError")
    ) {
        await logoutAPI();
        window.location.href = "/login";
    }

    toast.error(error.response.data.name);

    return;
  }
);
