import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { FormCheck } from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";
import Select from "../Select";
import MultiSelect from "../MultiSelect";
import Button from "../Button";
import Spinner from "../Spinner";
import style from "./snaplogicstep.module.css";
import { formatUrl, get } from "../../utils/api";
import { toast } from "react-toastify";
import { useProjects } from "../../contexts/ProjectsContext";
import useDebounce from "../../hooks/useDebounce";
import PipelineNameFilter from "../CreateDocumentation/PipelineNameFilter";

const cookies = new Cookies();

const SnapLogicStep = ({
  targetSystem,
  selectedOrganization,
  selectedProjectSpace,
  selectedProjects,
  setSelectedProjectSpace,
  setSelectedProjects,
  setIsCurrentSelectedOrganization,
  isCurrentSelectedOrganization,
}) => {
  const user = cookies.get("user");
  const user_oauth2 = localStorage.getItem('user_oauth')
  const {
    projects,
    setProjects,
    projectsWithConfigFiles,
    setProjectsWithConfigFiles,
    setSelectedProjectsWithConfigFiles,
    storeConfigFileLocationOnly,
    setStoreConfigFileLocationOnly,
    setStartsWithFilter,
    setContainsFilter,
  } = useProjects();
  const [showSpinner, setShowSpinner] = useState(false);
  const [noOrganization, setNoOrganization] = useState(false);
  const [includeConfigFiles, setIncludeConfigFiles] = useState(false);
  const [isFetchingConfigFiles, setIsFetchingConfigFiles] = useState(false);
  const [selectedConfigOptions, setSelectedConfigOptions] = useState([]);

  const [configFileOptions, setConfigFileOptions] = useState([]);

  const debouncedProjectSpace = useDebounce(selectedProjectSpace, 1500);

  const callApi = async () => {
    setNoOrganization(false);
    setShowSpinner(true);

    if (targetSystem && selectedOrganization) {
      const apiUrl = formatUrl("documentation", {
        organization: selectedOrganization,
        targetSystem: targetSystem,
        email: user ? user.user : user_oauth2
      });

      try {
        const response = await get(apiUrl);
        if (response) {
          if (
            projects.length === 0 ||
            response.request.responseURL.includes(selectedOrganization)
          ) {
            setIsCurrentSelectedOrganization(true);
          }

          setProjects(response.data);
        }
      } catch (error) {
        if (error.message === "Request failed with status code 404") {
          setNoOrganization(true);
        }

        toast.error(error.response.data.name || "There was some Error!");
      } finally {
        setShowSpinner(false);
      }
    } else {
      toast.error("Missing target system or organization. Please reload the page")
    }
  };

  const onReloadClick = () => {
    callApi();

    setSelectedProjectSpace("");
    setSelectedProjects([]);

    setSelectedProjectsWithConfigFiles([]);
    setProjectsWithConfigFiles([]);
    setStoreConfigFileLocationOnly(false);

    setStartsWithFilter("");
    setContainsFilter("");
  };

  useEffect(() => {
    if(targetSystem) {
      callApi();
    }
  }, [targetSystem]);

  useEffect(() => {
    if (selectedOrganization === "") {
      setNoOrganization(true);
    } else {
      if (isCurrentSelectedOrganization && targetSystem) {
        callApi();
      }

      if (noOrganization) {
        callApi();
      }

      setSelectedProjectSpace("");
      setSelectedProjects([]);

      setSelectedProjectsWithConfigFiles([]);
      setProjectsWithConfigFiles([]);
      setStoreConfigFileLocationOnly(false);

      setIsCurrentSelectedOrganization(false);

      setStartsWithFilter("");
      setContainsFilter("");
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (!debouncedProjectSpace || debouncedProjectSpace === "") {
      return;
    }

    const configFilesUrl = formatUrl("configurationFiles", {
      organization: selectedOrganization,
      projectSpace: debouncedProjectSpace,
    });

    setIsFetchingConfigFiles(true);

    get(configFilesUrl)
      .then((resp) => {
        setProjectsWithConfigFiles(resp.data.configFiles);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404") {
          setNoOrganization(true);
        }

        toast.error(error.response.data.name || "There was some Error!");
      })
      .finally(() => {
        setIsFetchingConfigFiles(false);
      });
  }, [debouncedProjectSpace]);

  useEffect(() => {
    const configFileHeadings = selectedProjects.map((project) => project.label);
    setConfigFileOptions(
      projectsWithConfigFiles
        .filter(
          (project) =>
            configFileHeadings.includes(project.project) &&
            project.files.length > 0
        )
        .map((project) => ({
          label: project.project,
          options: project.files.map((file) => ({
            label: file,
            value: { project: project.project, file: file },
          })),
        }))
    );

    setSelectedConfigOptions((prev) =>
      prev.filter((selectedOption) =>
        configFileHeadings.includes(selectedOption.value.project)
      )
    );
  }, [projectsWithConfigFiles, selectedProjects]);

  const confluenceProjectData =
    targetSystem.toLowerCase() === "confluence" &&
    projects.length > 0 &&
    !projects[0].confluenceStatus;

  const projectSpaceDisabled = projects.length === 0 || confluenceProjectData;

  const projectDisabled = !selectedProjectSpace;

  const projectSpaceArray = projects.length > 0 ? Object.keys(projects[0]) : [];

  const projectSpaceOptions = projectSpaceArray.filter(
    (projectSpace) => projectSpace !== "confluenceStatus"
  );

  const projectOptions =
    projects.length > 0 && selectedProjectSpace
      ? projects[0][selectedProjectSpace].map((project) => ({
          value: project,
          label: project,
        }))
      : [];

  const onSelectProjectSpace = async (e) => {
    setSelectedProjectSpace(e.target.value);
    setSelectedProjects([]);
    setProjectsWithConfigFiles([]);
    setSelectedProjectsWithConfigFiles([]);
    setSelectedConfigOptions([]);
    setIncludeConfigFiles(false);
    setStoreConfigFileLocationOnly(false);

    setStartsWithFilter("");
    setContainsFilter("");
  };

  const handleProjectChange = (e) => {
    setSelectedProjects(e);
  };

  const handleCheckIncludeConfig = () => {
    setIncludeConfigFiles((prev) => !prev);
  };

  const handleCheckStoreFileLocation = () => {
    setStoreConfigFileLocationOnly((prev) => !prev);
  };

  const handleConfigSelectChange = (newValue) => {
    setSelectedConfigOptions(newValue);

    const newSelectedProjectsWithConfigFiles = [];

    newValue
      .map((elem) => elem.value.project)
      .reduce((acc, curr) => {
        if (!acc.includes(curr)) {
          acc.push(curr);
        }
        return acc;
      }, [])
      .forEach((project) =>
        newSelectedProjectsWithConfigFiles.push({
          project: project,
          files: [],
        })
      );

    newValue
      .map((elem) => elem.value)
      .forEach(({ project, file }) => {
        newSelectedProjectsWithConfigFiles
          .find((newProject) => newProject.project === project)
          ?.files.push(file);
      });

    setSelectedProjectsWithConfigFiles(newSelectedProjectsWithConfigFiles);
  };

  if (noOrganization) {
    return (
      <p className="error text-center my-5 ">
        Please select valid organization or contact support team
      </p>
    );
  }

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-column mt-5 mx-4">
          <div className="w-75">
            <h2 className={`${style.header}`}>Choose Your SnapLogic Project</h2>
            <p className={`${style.text}`}>
              Please choose your SnapLogic project space and one or multiple
              projects that you want to document.
            </p>
            <div className="d-flex justify-content-between align-items-end mt-5 mb-3">
              <Select
                id="project-space"
                size="lg"
                options={projectSpaceOptions}
                customClassNames={["custom_select"]}
                defaultValue="Select Project Space"
                label="Select Project Space*"
                disabled={projectSpaceDisabled}
                value={selectedProjectSpace}
                onChange={(e) => onSelectProjectSpace(e)}
                wrapperClasses={["custom_wrapper"]}
              />
              <Button
                customClassNames={["icon_button"]}
                onClick={onReloadClick}
              >
                <ArrowClockwise />
              </Button>
              <MultiSelect
                id="project"
                options={projectOptions}
                customClassNames={["custom_select"]}
                label="Select Project*"
                disabled={projectDisabled}
                value={selectedProjects}
                onChange={handleProjectChange}
                wrapperClasses={["custom_wrapper"]}
                placeholder="Select Project"
              />
            </div>
            {selectedProjects.length > 0 ? (
              <div className="w-100 d-flex flex-column mb-3 mt-4">
                <PipelineNameFilter />

                <FormCheck
                  id="include-config-files"
                  type="checkbox"
                  label="Include config files"
                  checked={includeConfigFiles}
                  onChange={handleCheckIncludeConfig}
                />

                {includeConfigFiles ? (
                  <>
                    {isFetchingConfigFiles ? (
                      <div className="w-50">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="ms-4 mt-3">
                        <MultiSelect
                          id="config-files"
                          options={configFileOptions}
                          customClassNames={["custom_select"]}
                          label="Files"
                          disabled={isFetchingConfigFiles}
                          value={selectedConfigOptions}
                          onChange={handleConfigSelectChange}
                          wrapperClasses={["custom_wrapper"]}
                          placeholder="Select JSON or EXPR files"
                        />

                        <div className="ms-4 mt-4">
                          <FormCheck
                            id="store-config-files-location-only"
                            type="checkbox"
                            label="Store file location only"
                            checked={storeConfigFileLocationOnly}
                            onChange={handleCheckStoreFileLocation}
                          />
                          <p className={`${style.text} ms-3`}>
                            *Do not store the file content, keep the file
                            location only.
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {confluenceProjectData ? (
              <p className="text-danger">
                Please check your Confluence settings first
              </p>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default SnapLogicStep;
