import React from "react";

function PurposeAndScope() {
  return (
    <div className="mt-5">
      <h4>Purpose and scope </h4>
      <p>Welcome to Pete, the Virtual Assistant for SnapLogic Documentation.</p>
      <p>
        Pete is a Software as a Service (SaaS) tool used to automatically create
        and update documentation for one or more SnapLogic projects.
      </p>
      <p>
        This Privacy Notice outlines how Pete as a product of INTERVORKS DOOEL –
        Bitola dba IWConnect (“Controller”, "Pete," "we," "our," or "us") collects, uses,
        disclose, and protects the information of Pete’s users. This Notice does
        not apply to any third-party applications or software that integrates
        with Pete’s Services or any other third-party products or services.
      </p>
      <p>
        Pete is designed, developed, and maintained by IWConnect. IWConnect uses
        its own{" "}
        <a
          href="https://iwconnect.com/products/pete/"
          target="_blank"
          rel="noreferrer"
        >
          Site
        </a>{" "}
        to promote and post any relevant information about Pete. Whenever ‘Site’
        or ‘website’ is used in this Privacy Notice, it refers to the
        IWConnect’s{" "}
        <a href="https://iwconnect.com/" target="_blank" rel="noreferrer">
          website
        </a>
        . This Site has its own Privacy Notice that explains how IWConnect
        collects, uses, discloses and protects information of its web visitors.
      </p>
      <p>
        By using Pete, you consent to the practices described in this Privacy
        Notice.
      </p>
    </div>
  );
}

export default PurposeAndScope;
