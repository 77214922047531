import React, { useState } from "react";
import styles from "./microsoftAuth.module.css";
import Button from "../../Button";
import Input from "../../Input";
import microsoftLogo from "../../../assets/images/microsoftLogo.svg";
import { loginOauth2Api } from "../../../utils/api";
import { getMsalConfig } from "../../../config/authConfig";
import { useAuth } from "../../../contexts/AuthContext";
import { toast } from "react-toastify";

const MicrosoftAuthentication = () => {
  const [organization, setOrganization] = useState("");
  const { login, setIsAuthenticated } = useAuth()

  const handleLogin = async () => {
    try {
      let response;
      try {
        const oauth2Response = await loginOauth2Api(
          "config",
          `?organizationName=${organization}`
        );
        response = await oauth2Response.json();
        if(response.statusCode === 404) {
          toast.error(response.name);
        }
      } catch (error) {
        throw error
      }

      if (response) {
        localStorage.setItem('REACT_APP_OAUTH_SCOPES', response.scopes)
        const msalConfig = getMsalConfig(response);
       const loginResponse = await login(msalConfig);
      
        if (loginResponse && loginResponse.account) {
          localStorage.setItem("authMethod", 'OAUTH2');
          setIsAuthenticated(true)
        }
      }
    } catch (error) {
      setOrganization("");
      throw error
    }
  };

  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
  };

  return (
    <>
      <div
        className={`${styles.orDivider} d-flex justify-content-center align-items-center my-3`}
      >
        <span>OR</span>
      </div>
      <div>
        <Input
          id="inputOrganization"
          placeholder="Enter Your Organization"
          className="form-control px-4"
          name="organization"
          value={organization}
          label="Organization"
          onChange={handleOrganizationChange}
        />
      </div>
      <Button
        type="button"
        className={`btn ${styles.btnMicrosoft}`}
        onClick={handleLogin}
        disabled={!organization}
      >
        <span className={styles.msLogo}>
          <img src={microsoftLogo} alt="Microsoft Logo" />
        </span>
        Continue with Microsoft
      </Button>
    </>
  );
};

export default MicrosoftAuthentication;
