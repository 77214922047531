import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import validator from "validator";

import AuthContainer from "../containers/Auth";
import Button from "./Button";
import Input from "./Input";
import Logo from "./UI/Logo/Logo";
import { POST_RESET_PASSWORD } from "../constants/authConstants";
import { put } from "../utils/api";
import { EyeSlash, Eye } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toLogin, setToLogin] = useState(false);
  const [isResetingPassword, setIsResetingPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordChange = async (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isStrongPassword(password, { minLength: 12 })) {
      setPasswordError(
        "Password should be at least 12 char, contain at least one uppercase, one lowercase, one number and one special character"
      );
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    const getToken = searchParams.get("token");

    let base64ToString = atob(getToken).split(":");

    const body = {
      password: password,
      confirmPassword: confirmPassword,
      user: base64ToString[0],
      token: base64ToString[1],
    };

    setIsResetingPassword(true);
    try {
      const response = await put(`${POST_RESET_PASSWORD}`, body);
      if (response.status === 200) {
        toast.success("Password has been reseted successfully");
        setPassword("");
        setConfirmPassword("");
        setToLogin(true);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResetingPassword(false);
    }
  };


  return (
    <>
      <AuthContainer>
        <Logo />
        <h3 className="display-4 my-4">Reset Password</h3>
        {toLogin ? (
          <>
            <Button className="w-100" onClick={() => navigate("/login")}>
              Return To Login
            </Button>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit} noValidate={true}>
              <Row className="align-items-center">
                <Col>
                  <Input
                    id="inputPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="form-control px-4"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handlePasswordChange}
                    showPasswordToggle={true}
                  />
                </Col>
              </Row>
              {passwordError && (
                <Row md={12}>
                  <label style={{ color: "red" }}>{passwordError}</label>
                </Row>
              )}
              <Row className="align-items-center">
                <Col>
                  <Input
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="form-control px-4"
                    name="password"
                    value={confirmPassword}
                    label="Confirm Password"
                    onChange={handleConfirmPasswordChange}
                    showPasswordToggle={true}
                  />
                </Col>
              </Row>
              {confirmPasswordError && (
                <Row md={12}>
                  <label style={{ color: "red" }}>{confirmPasswordError}</label>
                </Row>
              )}
              <Button
                type="submit"
                className="w-100"
                disabled={isResetingPassword}
              >
                Reset Password
              </Button>
            </form>
          </>
        )}
      </AuthContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ResetPassword;
