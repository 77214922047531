import React from "react";
import style from "./index.module.css";

function UsingOrgMail() {
  return (
    <div className="mt-4">
      <h6>Using org email</h6>
      <p>
        You can create an email that will be used for documentation (or use some
        already generated user), grant read only permissions to the projects you
        want to document.
      </p>
      <ul className={style["disc-list"]}>
        <li>
          Step 1: Create an organizational email for documentation purposes (or
          use already existing email).
        </li>
        <li>
          Step 2: If the email is added into SnapLogic make sure it has UI
          access. If the email is not added please add it into your SnapLogic
          organization as a regular user with UI access.
        </li>
        <li>
          Step 3: Login to Pete’s application (
            <a href="https://www.pete-va.iwconnect.com/" target="_blank" rel="noreferrer">
            www.pete-va.iwconnect.com
          </a>
          ) (you might notice some error notification at this point, discard
          them).
        </li>
        <li>
          Go into My Profile and enter the email address and password for the
          SnapLogic user you added in step 2.
        </li>
      </ul>
    </div>
  );
}

export default UsingOrgMail;
