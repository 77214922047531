import React, { useState } from "react";
import Title from "../../components/UI/Title";
import Button from "../../components/Button";
import ChangeShortPasswordModal from "../../components/ChangeShortPasswordModal";

const ChangePassword = () => {
  const [showModal, setShowModal] = useState(false);

  const onClick = () => {
    setShowModal(true)
  };

  return (
    <>
      <Title title="Change Password" />
      <label>
        Maintaining the security of your account is our top priority. To comply
        with security policies and protect your personal data, please update
        your password periodically.
      </label>
      <div className="d-flex justify-content-start" style={{paddingTop: '20px' }}>
        <Button onClick={onClick}>Change Password</Button>
      </div>
      { showModal && (
        <ChangeShortPasswordModal 
        show={showModal}
        setShow={setShowModal}
        closeButton={true}
        showNewPolicyText={false}
        />
      )}
    </>
  );
};

export default ChangePassword;
