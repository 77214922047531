import React from "react";

function ChildrenPersonalData() {
  return (
    <div className="mt-5">
      <h4>Children's Personal Data</h4>
      <p>
        We do not knowingly collect Personal Data from any person under the age of 16. We recognize the privacy interests of children, and we encourage parents and guardians to take an active role in their children’s online activities and interests. Pete is not intended for children under the age of 16 and we endeavor not to collect any personally identifiable information from children under the age of 16. We kindly ask any child under the age of 16 not to submit any personal data to us or use Pete.
      </p>
      <p>If a user submitting Personal Data is suspected of being younger than 16 years of age, we will require the user to close his or her account and will not allow the user to continue using our Service. We will also take steps to delete the information as soon as possible.  </p>
      <p>If you believe that we have collected information about a child under 16 years old, please contact us at <a href="mailto: privacy@iwconnect.com">privacy@iwconnect.com</a>, so we can act to prevent access to them and delete the information.</p>
    </div>
  );
}

export default ChildrenPersonalData;
