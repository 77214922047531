import React from 'react';
import { Nav } from 'react-bootstrap';

const Tab = ({
  id,
  className,
  children,
  eventKey
}) => (
  <Nav.Item id={id} className={className}>
    <Nav.Link eventKey={eventKey}>{children}</Nav.Link>
  </Nav.Item>
);

export default Tab;
