import React from 'react'
import style from "./index.module.css";

const PrivacyRights = () => {
    return (
        <div className="mt-5">
            <h4>Your Privacy Rights</h4>
            <p>
                You have many rights regarding your personal data. Please read more about them below. If you would like to exercise them or learn more, feel free to contact us at <a href="mailto: privacy@iwconnect.com">privacy@iwconnect.com</a>. Contact details can be found at the end of this statement as well. Please note that some of the rights may not be applicable to your situation.
            </p>
            <ul>
                <li><strong>Right to be informed</strong> – You have the right to know what personal data we process and why. That’s why we inform you in advance about our processing activities via this Privacy Statement; </li>
                <li><strong>Right of access</strong> – You have the right to receive confirmation from us that we process your personal data, and if that is the case, to provide you with access to it, i.e. a copy, in order to be aware of the data processing and to be able to confirm the legality of the processing of the data;</li>
                <ul className={style["no-bullets"]}>
                    <li>
                        <ul className={style["no-bullets"]}>
                            <li><p>The request for access to personal data must be made in writing and in order to be able to carry out the procedure, please initiate it at privacy@iwconnect.com.To be able to provide a more efficient response, please include in the request specific details of the data you want to view.</p></li>
                            <li><p>After receiving the request, it is possible to contact you and ask you for proof of your identity. This proof must be provided in order to fulfill your request. Also, if there is not enough information in the request, it is possible to contact you to supplement the request with certain information.</p></li>
                            <li><p>Your request will be answered free of charge. However, we may charge a reasonable fee if you request additional copies of the same information. The fee charged will be based on the administrative costs of providing the requested information.</p></li>
                            <li><p>Pete may reject your request if it is manifestly unfounded or excessive, or if it is repeated. In this case, within the legally established deadline after receiving the request, we will explain why we reject your request.</p></li>
                        </ul>
                    </li>
                </ul>
                <li><strong>Right to rectification</strong> – If you believe we store wrong information about you, such as your name or address, you can ask us to correct this.</li>
                <li><strong>Right to erasure / right to be forgotten</strong> - You have the right to tell us to permanently erase your personal data from our records. You can do this for example if you believe there’s no longer any need for us to keep it. Or, if you previously have given your permission, you can just decide to withdraw it.</li>
                <li><strong>Right to restrict the processing activities</strong> -You have the right to restrict our processing activities in certain situations. This means we will continue to store your information, but we’ll temporarily stop any other processing. Why would you want to do this? For example, if you’ve asked us to fix incorrect information. In this situation you may want us to stop processing until the information is correct and if this is the case just contact us.</li>
                <li><strong>Right to object</strong> – You have the right to object to the processing of your information, even when we have a legitimate legal reason to process it. You can do this when we process your information on the basis of our legitimate interest, and you believe that your personal interest outweighs ours. If you do not want us using your information for direct marketing purposes, including profiling, we will comply in any case.</li>
                <li><strong>Right to withdraw consent</strong> – If we process your personal data based on your consent, you have the right to withdraw your consent anytime.</li>
                <li><strong>Right to data portability</strong> – In certain situations, you have the right to ask us to send your personal data in digital form to you, so that you can forward it to someone else. We are obliged to give them to you in a format that will be usable for you for that purpose, if it is technically possible.</li>
                <li><strong>Right to lodge a complaint with a supervisory authority</strong> – If you are not satisfied with our response to your requests for executing your privacy rights or you believe our processing of your personal data is not compliant with the protection law, you have the right to lodge a complaint about us to the national supervisory authority. The supervisory body in the Republic of Northern Macedonia for personal data protection is the Agency for Personal Data Protection.</li>
            </ul>
        </div>
    )
}

export default PrivacyRights
