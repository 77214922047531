import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import validator from "validator";
import { Col, Row } from "react-bootstrap";
import { EyeSlash, Eye } from "react-bootstrap-icons";
import Input from "./Input";
import { post } from "../utils/api";
import Button from "./Button";

// TODO - use modal component everywhere instead of importing modal from react-bootstrap multiple times!!!
const ChangePasswordModal = ({ show, setShow }) => {
  const navigate = useNavigate();
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordChange = async (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleChangePasswordFormSubmit = async (event) => {
    event.preventDefault();

    if (!validator.isStrongPassword(password, { minLength: 12 })) {
      setPasswordError(
        "Password should be at least 12 char, contain at least one uppercase, one lowercase, one number and one special character"
      );
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    const body = {
      password: password,
      confirmPassword: confirmPassword,
    };
    setIsChangingPassword(true);
    try {
      const response = await post("user/password", body);

      if (response.status === 200) {
        toast.success("Password has been changed successfully");
        setPassword("");
        setConfirmPassword("");
        setShow(false);
        navigate("/new");
      }
    } catch (error) {
      toast.error(error.response.data.name);
    } finally {
      setIsChangingPassword(false);
    }
  };

  return (
    <>
      <Modal show={show} backdrop="static">
        <Modal.Header>
          <h3 className="display-4">Change Password</h3>
        </Modal.Header>
        <form onSubmit={handleChangePasswordFormSubmit}>
          <Modal.Body>
            <Row className="align-items-center">
              <Col>
                <Input
                  id="inputPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="form-control px-4"
                  style={{ border: "1px solid #ced4da75" }}
                  name="password"
                  value={password}
                  label="Password"
                  onChange={handlePasswordChange}
                  error={passwordError}
                  showPasswordToggle={true}
                />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="form-control px-4"
                  style={{ border: "1px solid #ced4da75" }}
                  name="password"
                  value={confirmPassword}
                  label="Confirm Password"
                  onChange={handleConfirmPasswordChange}
                  error={confirmPasswordError}
                  showPasswordToggle={true}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "0px", paddingBottom: "20px" }}>
            <Button
              type="submit"
              className="w-100"
              disabled={isChangingPassword}
            >
              Change Password
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ChangePasswordModal;
