import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import jwt from "jwt-decode";
import validator from "validator";
import AuthContainer from "../containers/Auth";
import Input from "./Input";
import { loginApi } from "../utils/api";
import ChangePasswordModal from "./ChangePasswordModal";
import SignUp from "./SignUp";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import Button from "./Button";
import Logo from "./UI/Logo/Logo";
import { useCookieConsent } from "../contexts/CookieConsentContext";

const cookies = new Cookies();

const Login = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const { functionalCookiesConsent } = useCookieConsent();
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginFormSubmit = async (event) => {
    event.preventDefault();

    const emailErrorMessage =
      email.trim().length === 0
        ? "Please enter an email address"
        : !validator.isEmail(email)
        ? "Enter a valid Email!"
        : "";

    const passwordErrorMessage =
      password.trim().length === 0
        ? "Please enter a password"
        : !validator.isStrongPassword(password)
        ? "Password should be at least 12 char, contain at least one uppercase, one lowercase, one number and one special character"
        : "";

    setEmailError(emailErrorMessage);
    setPasswordError(passwordErrorMessage);

    if (!emailErrorMessage && !passwordErrorMessage) {
      setIsSigning(true);

      try {
        const response = await loginApi("user/login", {
          user: email,
          password,
        });

        localStorage.setItem("authMethod", 'BASIC');
        const user = {
          expiresIn: response.data && response.data.expiresIn,
          firstLogin: response.data && response.data.firstLogin,
          token: response.data && response.data.accessToken,
          updated: response.data && response.data.updated
        };

        if (user.updated === 0 || !user.updated) {
          localStorage.setItem("shortPassword", "true");
        }

        if (user.firstLogin) {
          cookies.set("user", user);
          cookies.set("expiresIn", response.data.expiresIn);
          setShowChangePassword(true);
        } else {
          cookies.set("user", user);
          cookies.set("expiresIn", response.data.expiresIn);
          navigate("/new");
          setEmail("");
          setPassword("");
        }
      } catch (error) {
        setIsSigning(false);
        toast.error(error.response.data.name);
      } finally {
        setIsSigning(false);
      }
    }
  };

  return (
    <>
      {isSigning && (
        <div className="login-overlay">
          <Spinner />
        </div>
      )}

      <AuthContainer>
        {/* <Logo /> */}

        <ChangePasswordModal
          show={showChangePassword}
          setShow={setShowChangePassword}
        />

        <h3 className="display-4 my-4">Sign In</h3>
        <form onSubmit={handleLoginFormSubmit} noValidate={true}>
          <Input
            id="inputEmail"
            type="email"
            placeholder="Enter Your Email Address"
            className="form-control px-4"
            name="email"
            value={email}
            label="Email"
            onChange={handleEmailChange}
            error={emailError}
          />

          <Input
            id="inputPassword"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Your Password"
            className="form-control px-4"
            name="password"
            value={password}
            label="Password"
            onChange={handlePasswordChange}
            error={passwordError}
            showPasswordToggle={true}
          />

          <Button type="submit" className="w-100">
            Sign In
          </Button>

          <div className="d-flex justify-content-between align-items-center  mt-4">
           {functionalCookiesConsent ? <SignUp /> : <div></div>}

            <p className="lb-fpass" onClick={() => navigate("/forgotpass")}>
              Forgot Password?
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <p
              className="privacy-notice-btn"
              onClick={() => navigate("/privacy-notice")}
            >
              Privacy Notice
            </p>
          </div>
        </form>
      </AuthContainer>
    </>
  );
};

export default Login;
