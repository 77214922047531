import React, { useState } from "react";
import style from "./index.module.css";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

function SnaplogicConnection() {
  const [isSnaplogicConnectionOpen, setIsSnaplogicConnection] = useState(false);

  const toggleSnaplogicConnection = () => {
    setIsSnaplogicConnection(!isSnaplogicConnectionOpen);
  };

  return (
    <div className="mt-3">
      <Row>
      <Col md={11} className="d-flex align-items-center">
          <button 
            onClick={toggleSnaplogicConnection} 
            className="btn btn-text p-0" 
            style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', font: 'inherit', cursor: 'pointer' }}
          >
            <h4>Snaplogic Connection</h4>
          </button>
        </Col>
        <Col
          md={1}
          onClick={toggleSnaplogicConnection}
          className="text-center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "-1rem" }}>
            {isSnaplogicConnectionOpen ? <ChevronDown /> : <ChevronUp />}
          </div>
        </Col>
      </Row>
      {isSnaplogicConnectionOpen ? (
        <Row>
          <p>
            To use Pete, you must have a valid SnapLogic organization and a
            valid SnapLogic user. The SnapLogic user (email and password) should
            be added to the Pete application in My Profile of the web
            application. We recommend granting <strong>read-only</strong> access
            to this user for the SnapLogic projects to be documented. This user
            should be used exclusively for documentation purposes. The SnapLogic
            account password is stored encrypted in Pete's Key Vault. One
            SnapLogic account can be used for documentation per SnapLogic
            organization.
          </p>
          <p>
            <strong>SnapLogic Information we collect and store</strong>
          </p>
          <ul className={style["disc-list"]}>
            <li>SnapLogic organization name</li>
            <li>SnapLogic email</li>
            <li>
              SnapLogic user password (encrypted and stored in a key vault)
            </li>
          </ul>
        </Row>
      ) : null}
    </div>
  );
}

export default SnaplogicConnection;
