import menuStyle from "./index.module.css";
import routesNav from "./routesNav";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import menuImage from "../../assets/images/PeteLogo.png";
import { IW_FOOTER_TXT_COPY, IW_FOOTER_TXT_MADE } from "../constants.js";
import { logoutAPI } from "../../service/auth.service";
import { useTargetSystemContext } from "../../contexts/TargetSystemContext";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext.js";
import { formatUrl, get } from "../../utils/api.js";
import { useEffect } from "react";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(true);
  const [clickedMenu, setclickedMenu] = useState(0);
  const [userRole, setUserRole] = useState("");
  const { targetSystem } = useTargetSystemContext();
  const selectedOrganization = useSelectedOrganization();

  const callApi = async () => {
    const url = formatUrl("user/profile", {
      organizationName: selectedOrganization,
    });
    const responseProfile = await get(url);
    setUserRole(responseProfile.data[0].userRole);
  };
  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization]);

  const menuHandle = () => {
    setOpenMenu(!openMenu);

    setclickedMenu((previous) => previous + 1);
  };

  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const displayedMenu = () => {
    if (isDesktop) {
      if (openMenu) return true;
      else return false;
    } else {
      if (clickedMenu === 0) return false;
      else {
        if (openMenu) return true;
        else return false;
      }
    }
  };

  return (
    <div
      className={
        displayedMenu()
          ? `${menuStyle.container} ${menuStyle.container_open}`
          : `${menuStyle.container} ${menuStyle.container_close}`
      }
    >
      <div className={menuStyle.responsive}>
        <div
          className={
            displayedMenu()
              ? `${menuStyle.iw_logo}`
              : `${menuStyle.display_none}`
          }
        >
          {" "}
        </div>
        <div
          className={
            displayedMenu()
              ? `${menuStyle.hamburger}`
              : `${menuStyle.hamburger_close}`
          }
          onClick={menuHandle}
        ></div>
      </div>

      {/* navigation  */}
      <nav className={menuStyle.menu} role="navigation">
        {routesNav[targetSystem.toLowerCase()].map((item) => (
          <div key={item.section} className={menuStyle.menu_section}>
            <div
              className={
                displayedMenu()
                  ? menuStyle.menu_section_item
                  : `${menuStyle.display_none}`
              }
            >
              {item.title}
            </div>
            <ul className={menuStyle.menu_ul}>
              {item.items.map((x, index) => (
                <li key={index}>
                  {x.path === "/logout" ? (
                    <a
                      onClick={() => {
                        logoutAPI();
                      }}
                      role="button"
                      className={menuStyle.menu_item}
                      key={x.path}
                      href="/login"
                    >
                      <img
                        src={x.image}
                        alt={x.image}
                        className={
                          displayedMenu()
                            ? menuStyle.menu_icon_item
                            : menuStyle.menu_icon_item_close
                        }
                        title={displayedMenu() ? "" : `${x.title}`}
                      />
                      <span
                        className={
                          displayedMenu() ? "" : `${menuStyle.display_none}`
                        }
                      >
                        {x.title}
                      </span>
                    </a>
                  ) : x.path === "/users" && userRole === 1 ? null : (
                    <NavLink
                      to={x.path}
                      className={({ isActive }) =>
                        isActive
                          ? menuStyle.menu_item_active
                          : menuStyle.menu_item
                      }
                      key={x.path}
                    >
                      <img
                        src={x.image}
                        alt={x.image}
                        className={
                          displayedMenu()
                            ? menuStyle.menu_icon_item
                            : menuStyle.menu_icon_item_close
                        }
                        title={displayedMenu() ? "" : `${x.title}`}
                      />
                      <span
                        className={
                          displayedMenu() ? "" : `${menuStyle.display_none}`
                        }
                      >
                        {x.title}
                      </span>
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>

      <div
        style={{ display: "flex", justifyContent: "center" }}
        className={
          displayedMenu() ? menuStyle.menu_bottom : menuStyle.display_none
        }
      >
        <img
          src={menuImage}
          alt={menuImage}
          style={{ margin: "auto", display: "block", width: "100%" }}
        />
      </div>
      <div
        className={
          displayedMenu() ? menuStyle.footer_text : menuStyle.display_none
        }
      >
        <div>
          <span dangerouslySetInnerHTML={{ __html: IW_FOOTER_TXT_COPY }} />
        </div>
        <div>{IW_FOOTER_TXT_MADE}</div>
        <div
          style={{ textAlign: "center", padding: "20px 25px" }}
          className={
            displayedMenu()
              ? `${menuStyle.iw_logo_bottom}`
              : `${menuStyle.display_none}`
          }
        >
          <img
            style={{ margin: "auto", display: "block", width: "100%" }}
            src="https://iwconnect.com/wp-content/uploads/2022/02/IWTM-01.svg"
            className="custom-logo"
            alt="⋮IWConnect"
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
