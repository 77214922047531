import React from 'react';
import LogoImg from '../../../assets/images/IW-Logo.svg';
import styles from './footerLogo.module.css';

const FooterLogo = () => {
  return (
    <div className="col-lg-10 col-xl-7 mx-auto d-flex align-items-center justify-content-around mt-5 mb-4">
      <span className={styles.footer_text}>Powered by</span>
      <img src={LogoImg} alt="⋮IWConnect" className={styles.footer_logo} />
    </div>
  );
};

export default FooterLogo;
