import React, { useState } from "react";
import Button from "../../components/Button";
import style from '../../components/DocumentSteps/confluencestep.module.css';
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import { post } from "../../utils/api";
import Input from '../../components/Input';
import { FormCheck } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";

const OrganizationSummary = () => {
  const selectedOrganization = useSelectedOrganization();

  const [newOrganizationSummaryPage, setNewOrganizationSummaryPage] = useState("");
  const [shouldCreateDefaultPage, setShouldCreateDefaultPage] = useState(true);
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);
  const [getExecutionIdFromResponse, setgetExecutionIdFromResponse] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleNewOrganizationSummaryPage = (e) => {
    setNewOrganizationSummaryPage(e.target.value);
  };

  const handleCheckDefaultPage = () => {
    setShouldCreateDefaultPage((prevState) => !prevState);
  };

  const organizationSummaryPage = shouldCreateDefaultPage
    ? 'Organization ' + selectedOrganization
    : newOrganizationSummaryPage;

    const onNewOrganizationSummaryClick = () => {
      setNewOrganizationSummaryPage("");
      setShowModal(false);
    };

  const onCreateClick = async () => {
    setIsCreatingDocument(true);
    try {
      const response = await post(
        "organization/summary",
        {
          SLOrganization: selectedOrganization,
          ConfluenceMainPage: organizationSummaryPage,
        },
        { timeout: 10 * 60 * 1000 }
      );

      setgetExecutionIdFromResponse(response.data[0]);
      toast.success(response.data[1]);
      setShowModal(true);
    } catch (error) {
      toast.error(error.response.data.name || "Something went wrong!");
    } finally {
      setNewOrganizationSummaryPage("")
      setIsCreatingDocument(false);
    }
  };

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button
        id="dashboard"
        onClick={() =>
          navigate("/dashboard", {
            state: { executionID: getExecutionIdFromResponse },
          })
        }
      >
        Dashboard
      </Button>
      <Button id="create-new-organization-summary" onClick={onNewOrganizationSummaryClick}>
        New Organization summary
      </Button>
    </div>
  );


  return (
    <>
      <div className="px-5">
    <div className="d-flex flex-column mt-5 mx-4">
      <div className="w-75">
        <h2 className={`${style.header}`}>Create Your Confluence Organization Main Page</h2>
        <p className={`${style.text}`}>
          You can create a new Confluence Organization Main Page or let the process create one for you by ticking <br/>
          the checkbox "Create Default Org Page". Confluence default page will be named same as SnapLogic
          Organization page.
        </p>
        <div className="d-flex justify-content-between mt-5 mb-3">
          {shouldCreateDefaultPage === true ? null : (
            <Input
              id="create-main-page"
              size="lg"
              customClassNames={['custom_input']}
              label="Organization Main Page"
              placeholder="Create New Main Page"
              value={newOrganizationSummaryPage}
              onChange={handleNewOrganizationSummaryPage}
              wrapperClasses={['custom_wrapper']}
            />
          )}
        </div>
          <FormCheck
            id="create-default-page"
            type="checkbox"
            label="Create Default Org Page"
            checked={shouldCreateDefaultPage}
            onChange={handleCheckDefaultPage}
          />
      </div>
    </div>
      </div>
      <div className="d-flex justify-content-between align-items-end mx-5 mt-3">
        <Button
          onClick={onCreateClick}
          showSpinner={isCreatingDocument}
        >
          Create
        </Button>
      </div>
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title="Document Creation Started"
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>
          Organization summary creation is in progress. Please see the status in the
          dashboard screen or create new organization summary.
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default OrganizationSummary;
