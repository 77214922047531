import { useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const PaginationComponent = ({ paginationInfo, setCurrentPage, alwaysShown = true }) => {
  const { currentPage, itemsPerPage, totalPages, totalItems } = paginationInfo;

  const isPaginationShown = alwaysShown ? true : totalPages > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === totalPages;

  const changePage = (number) => {
    if (currentPage === number) return;
    setCurrentPage(number);
    scrollToTop();
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage((currentPage) => currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage((currentPage) => currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  };

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(totalPages)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === totalPages;
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2;

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [totalPages]);

  return (
    <>
      {isPaginationShown && (
        <Pagination size="sm" className="mt-5 justify-content-center justify-content-md-end">
          <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst}>
            Prev
          </Pagination.Prev>
          {pageNumbers}
          <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast}>
            Next
          </Pagination.Next>
        </Pagination>
      )}
    </>
  );
};

export default PaginationComponent;
