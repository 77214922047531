import React from "react";

function DataTransfers() {
  return (
    <div className="mt-5">
      <h4>Data transfers</h4>
      <p>
        We will protect your Personal Data in accordance with this Privacy
        Notice wherever it is processed and will take appropriate contractual or
        other steps to protect the relevant Personal Data in accordance with
        applicable laws. Pete uses third-party sub processors (region West
        Europe) to provide services. Data transfers may occur as necessary for
        these services.
      </p>
    </div>
  );
}

export default DataTransfers;
