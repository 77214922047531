import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";
import ConfluenceDashboardTable from "./DashboardTable/ConfluenceDashboardTable";
import PDFDashboardTable from "./DashboardTable/PDFDashboardTable";
import DashboardPagination from "./DashboardPagination";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import { DASHBOARD_GET_API } from "../../constants/modulesApisConstant";
import DashboardSearch from "./DashboardSearch";
import { get } from "../../utils/api";
import { useTargetSystemContext } from "../../contexts/TargetSystemContext";
import SharepointDashboardTable from "./DashboardTable/SharepointDashboardTable";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedOrganization = useSelectedOrganization();
  const { targetSystem } = useTargetSystemContext();

  const [projectsData, setProjectsData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [userParam, setUserParam] = useState("");
  const [page, setPage] = useState(paginationInfo.currentPage || 1);
  const [limit, setLimit] = useState(paginationInfo.itemsPerPage || 10);
  const [executionID, setExecutionID] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [shouldClearAdvancedSearch, setShouldClearAdvancedSearch] =
    useState(true);
  const [advancedSearchApplied, setAdvancedSearchApplied] = useState(false);

  const [intervalId, setIntervalId] = useState();

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
    setPage(1);
    callApi(true)
  };

  const callApi = async (showLoader) => {
    if (showLoader) {
      setIsFetchingData(true);
    }

    const params = new URLSearchParams();
    params.append("page", page || 1);
    params.append("limit", limit || 10);
    params.append("organization", selectedOrganization);

    if (projectName.trim()) {
      params.append("projectName", projectName.trim());
    }
    if (userParam.trim()) {
      params.append("user", userParam.trim());
    }
    if (executionID.trim()) {
      params.append("executionID", executionID.trim());
    }
    if (startDateTime) {
      params.append("startDateTime", startDateTime);
    }
    if (endDateTime) {
      params.append("endDateTime", endDateTime);
    }
    if (status) {
      params.append("status", status);
    }
    if (sortBy) {
      params.append("sortBy", sortBy);
    }
    if (sortType) {
      params.append("sortType", sortType);
    }
    if (targetSystem) {
      params.append("targetSystem", targetSystem);
    }

    const apiUrl = `${DASHBOARD_GET_API}?${params.toString()}`;

    try {
      const response = await get(apiUrl);
      if (!response?.data) {
        return;
      }
      setProjectsData(response.data[0]);
      setPaginationInfo(response.data[1]);
    } catch (error) {
      toast(error.message);
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [targetSystem]);

  useEffect(() => {
    // Cleanup when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setProjectName("");
    setUserParam("");
    setExecutionID("");
    setEndDateTime("")
    setStatus("")
    setShouldClearAdvancedSearch(true);
    setAdvancedSearchApplied(false);
  }, [targetSystem, selectedOrganization]);

  useEffect(() => {
    if (location.state) {
      setExecutionID(location.state.executionID);
      setIsSearching(true);
      navigate(location.pathname, { replace: true });
    } else {
      if (selectedOrganization) {
        clearInterval(intervalId);
        callApi(true);

        const interval = setInterval(() => callApi(false), 15000);

        setIntervalId(interval);
      }
    }
  }, [selectedOrganization, isSearching, page, targetSystem]);

  useEffect(() => {
    if (!projectsData || projectsData.length === 0) {
      return;
    }

    // project.status 0 is status "In progress"
    if (
      !projectsData.some((project) => project.status === 0) &&
      intervalId !== undefined
    ) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [projectsData]);


  return (
    <>
      <Container as={"main"} fluid className="px-md-5 dashboard">
        <form onSubmit={handleSearch}>
          <DashboardSearch
            isSearching={isSearching}
            projectName={projectName}
            setProjectName={setProjectName}
            userParam={userParam}
            setUserParam={setUserParam}
            executionID={executionID}
            setExecutionID={setExecutionID}
            status={status}
            setStatus={setStatus}
            startDateTime={startDateTime}
            setStartDateTime={setStartDateTime}
            isFetchingData={isFetchingData}
            handleSearch={handleSearch}
            shouldClearAdvancedSearch={shouldClearAdvancedSearch}
            setShouldClearAdvancedSearch={setShouldClearAdvancedSearch}
            advancedSearchApplied={advancedSearchApplied}
            setAdvancedSearchApplied={setAdvancedSearchApplied}
            setIsSearching={setIsSearching}
            callApi={callApi}
          />
        </form>

        {isFetchingData ? (
          <div className="table-spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            {targetSystem.toLowerCase() === "pdf" ? (
              <PDFDashboardTable projectsData={projectsData} />
            ) : (
              <></>
            )}
            {targetSystem.toLowerCase() === "confluence" ? (
              <ConfluenceDashboardTable projectsData={projectsData} />
            ) : (
              <></>
            )}
            {targetSystem.toLowerCase() === "sharepoint" ? (
              <SharepointDashboardTable projectsData={projectsData} />
            ) : (
              <></>
            )}
          </>
        )}
        <DashboardPagination
          paginationInfo={paginationInfo}
          setCurrentPage={setPage}
          alwaysShown={false}
        />
      </Container>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Dashboard;
