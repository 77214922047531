import React from "react";
import { infoPages } from "../../constants/infoPages";
import { Link, useLocation } from "react-router-dom";
import style from "./index.module.css";

function Sidebar() {
  const { pathname } = useLocation();
  return (
    <div
      className="d-inline-block mt-3"
      style={{
        flexBasis: "18%",
      }}
    >
      {infoPages.map((page) => (
        <Link
          to={`/${page.slug}`}
          key={page.slug}
          className={`${style["info-page-link"]} ${
            pathname.slice(1) === page.slug ? style["active"] : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill={pathname.slice(1) === page.slug ? "#c12331" : "currentColor"}
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
          <span>{page.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default Sidebar;
