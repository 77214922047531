import React from "react";
import OrganizationSetup from "./sections/OrganizationSetup";
import SnapLogic from "./sections/SnapLogic";
import Sharepoint from "./sections/Sharepoint";

function Content() {
  return (
    <div style={{ flexBasis: "80%" }}>
      <OrganizationSetup />
      <SnapLogic />
      <Sharepoint/>
    </div>
  );
}

export default Content;
