import React from 'react';
import Select from 'react-select';
import { FormLabel } from 'react-bootstrap';
import { classLister } from '../../utils/style';
import style from './index.module.css';

const MultiSelect = ({
  id,
  className,
  customClassNames,
  options,
  disabled,
  value,
  onChange,
  getValue,
  setValue,
  label,
  wrapperClasses,
  placeholder
}) => {
  const customClasses = classLister(style)(customClassNames);
  const customWrapperClasses = classLister(style)(wrapperClasses);

  return (
    <div className={`d-flex flex-column ${customWrapperClasses}`}>
      <FormLabel className={`${style.custom_label}`} htmlFor={id}>
        {label}
      </FormLabel>
      <Select
        id={id}
        className={`${className || ''} ${customClasses}`}
        options={options}
        isMulti
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        getValue={getValue}
        setValue={setValue}
        label={label}
        placeholder={placeholder}
      />
    </div>
  );
};

MultiSelect.defaultProps = {
  label: '',
  disabled: false,
  customClassNames: [],
  wrapperClasses: []
};

export default MultiSelect;
