import React from "react";
import style from "./index.module.css";
import CreateAppRegistration from "./CreateAppRegistration";
import SetAPIPermissions from "./SetAPIPermissions";
import PeteSetup from "./PeteSetup";

function Sharepoint() {
  return (
    <div className="mt-5">
      <h4>Sharepoint</h4>
      <ul className={style["list-none"]}>
        <li>
          <CreateAppRegistration />
        </li>
        <li>
          <SetAPIPermissions />
        </li>
        <li>
          <PeteSetup />
        </li>
      </ul>
    </div>
  );
}

export default Sharepoint;
