import React from "react";

function ConfluenceEmail() {
  return (
    <div className="mt-4">
      <h6>Confluence Email</h6>
      <ul>
        <li>Email address of your confluence account use for documentation</li>
      </ul>
    </div>
  );
}

export default ConfluenceEmail;
