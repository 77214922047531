import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getMsalInstance,
  initializeMsal,
} from "../components/UI/MicrosoftAuth/msalService.js";
import { loginRequest } from "../config/authConfig.js";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('userAuthenticated'));

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const msal = getMsalInstance();
      const account = msal?.getActiveAccount();

      if (msal && account) {
        const expirationTime = msal?.getAccountByHomeId(
          msal?.getActiveAccount().homeAccountId
        ).idTokenClaims.exp;

        const currentTime = new Date().getTime() / 1000;

        if (expirationTime - currentTime < 5 * 60) {
            const refreshedToken = await msal
              .acquireTokenSilent({
                ...loginRequest,
                account: account,
                forceRefresh: false,
              })
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  return msal.acquireTokenPopup({...loginRequest,
                    account: msal?.getActiveAccount(),
                    forceRefresh: false});
                }
              });

            if (refreshedToken) {
              cookies.set('userOAuth', refreshedToken.idToken)
              console.log("Token Refreshed Successfully:");
            }
        }
      }
    };

    const intervalId = setInterval(checkAndRefreshToken, 300000); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const userOauthToken = cookies.get('userOAuth')
          if (userOauthToken) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
  }, []);

  const login = async (config) => {
    await initializeMsal(config);
    const msal = getMsalInstance();

    try {
      const loginResponse = await msal.loginPopup({
        ...loginRequest,
      });

      if (loginResponse && loginResponse.account) {
        localStorage.setItem("authMethod", "OAUTH2");
        setIsAuthenticated(true);

      }

      return loginResponse;
    } catch (error) {
      setIsAuthenticated(false);
      console.error("Error during MSAL login:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
