import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FormCheck } from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";
import Select from "../Select";
import MultiSelect from "../MultiSelect";
import Button from "../Button";
import Spinner from "../Spinner";
import style from "./pdfstep.module.css";
import { formatUrl, get, post } from "../../utils/api";
import { toast } from "react-toastify";
import { useProjects } from "../../contexts/ProjectsContext";
import useDebounce from "../../hooks/useDebounce";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import PipelineNameFilter from "../CreateDocumentation/PipelineNameFilter";
import validator from "validator";

const PDFStep = forwardRef(
  (
    {
      targetSystem,
      setShowModal,
      setGetExecutionIdFromResponse,
      setErrorModalMessage,
      setErrorModalAssets,
      setIsErrorModalOpen,
    },
    ref
  ) => {
    const {
      projects,
      setProjects,
      // projectsWithConfigFiles,
      // setProjectsWithConfigFiles,
      // setSelectedProjectsWithConfigFiles,
      // selectedProjectsWithConfigFiles,
      // storeConfigFileLocationOnly,
      // setStoreConfigFileLocationOnly,
      setSelectedProjectSpace,
      selectedProjectSpace,
      setSelectedProjects,
      selectedProjects,
      startsWithFilter,
      containsFilter,
    } = useProjects();

    const [isCreatingDocument, setIsCreatingDocument] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const [noOrganization, setNoOrganization] = useState(false);
    // const [includeConfigFiles, setIncludeConfigFiles] = useState(false);
    // const [isFetchingConfigFiles, setIsFetchingConfigFiles] = useState(false);
    // const [selectedConfigOptions, setSelectedConfigOptions] = useState([]);
    const [isCurrentSelectedOrganization, setIsCurrentSelectedOrganization] =
      useState(false);
    const [addAdditionalEmailRecipients, setAddAdditionalEmailRecipients] =
      useState([]);
    const [
      shouldAddAdditionalEmailRecipients,
      setShouldAddAdditionalEmailRecipients,
    ] = useState(false);
    const [currentEmail, setCurrentEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const selectedOrganization = useSelectedOrganization();
    let isMounted;

    // const [configFileOptions, setConfigFileOptions] = useState([]);

    const debouncedProjectSpace = useDebounce(selectedProjectSpace, 1500);

    useImperativeHandle(ref, () => ({
      clearFields() {
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setShowModal(false);
      },
      confirmCreate() {
        onCreateClick(true);
      },
      handleModalClose() {
        setIsCreatingDocument(false);
      },
    }));

    const callApi = async () => {
      isMounted = true;
      setNoOrganization(false);
      setShowSpinner(true);
      if (targetSystem && selectedOrganization) {
        const apiUrl = formatUrl("documentation", {
          organization: selectedOrganization,
          targetSystem: targetSystem,
        });

        try {
          const response = await get(apiUrl);
          if (response && isMounted) {
            if (
              projects.length === 0 ||
              response.request.responseURL.includes(selectedOrganization)
            ) {
              setIsCurrentSelectedOrganization(true);
            }

            setProjects(response.data);
          }
        } catch (error) {
          if (
            error.message === "Request failed with status code 404" &&
            isMounted
          ) {
            setNoOrganization(true);
          }

          toast.error(error.response.data.name || "There was some Error!");
        } finally {
          if (isMounted) {
            setShowSpinner(false);
          }
        }
      } else {
        toast.error("Missing target system or organization. Please reload the page")
      }
    };

    const onReloadClick = () => {
      callApi();
      setSelectedProjectSpace("");
      setSelectedProjects([]);
      // setSelectedProjectsWithConfigFiles([]);
      // setProjectsWithConfigFiles([]);
      // setStoreConfigFileLocationOnly(false);
    };

    useEffect(() => {
      if (selectedOrganization && targetSystem) {
        callApi();
        return () => {
          isMounted = false;
        };
      }
    }, [selectedOrganization, targetSystem]);

    useEffect(() => {
      if (selectedOrganization === "") {
        setNoOrganization(true);
      } else {
        if (isCurrentSelectedOrganization && targetSystem) {
          callApi();
        }

        if (noOrganization) {
          callApi();
        }
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        // setSelectedProjectsWithConfigFiles([]);
        // setProjectsWithConfigFiles([]);
        // setStoreConfigFileLocationOnly(false);
        setIsCurrentSelectedOrganization(false);
      }
    }, [selectedOrganization]);

    // useEffect(() => {
    //   if (!debouncedProjectSpace || debouncedProjectSpace === "") {
    //     return;
    //   }

    //   const configFilesUrl = formatUrl("configurationFiles", {
    //     organization: selectedOrganization,
    //     projectSpace: debouncedProjectSpace,
    //   });

    //   setIsFetchingConfigFiles(true);

    //   get(configFilesUrl)
    //     .then((resp) => {
    //       setProjectsWithConfigFiles(resp.data.configFiles);
    //     })
    //     .catch((error) => {
    //       if (error.message === "Request failed with status code 404") {
    //         setNoOrganization(true);
    //       }

    //       toast.error(error.response.data.name || "There was some Error!");
    //     })
    //     .finally(() => {
    //       setIsFetchingConfigFiles(false);
    //     });
    // }, [debouncedProjectSpace]);

    // useEffect(() => {
    //   const configFileHeadings = selectedProjects.map(
    //     (project) => project.label
    //   );
    //   setConfigFileOptions(
    //     projectsWithConfigFiles
    //       .filter(
    //         (project) =>
    //           configFileHeadings.includes(project.project) &&
    //           project.files.length > 0
    //       )
    //       .map((project) => ({
    //         label: project.project,
    //         options: project.files.map((file) => ({
    //           label: file,
    //           value: { project: project.project, file: file },
    //         })),
    //       }))
    //   );

    //   setSelectedConfigOptions((prev) =>
    //     prev.filter((selectedOption) =>
    //       configFileHeadings.includes(selectedOption.value.project)
    //     )
    //   );
    // }, [projectsWithConfigFiles, selectedProjects]);

    useEffect(() => {
      if (!emailError) {
        setCurrentEmail("");
      }
    }, []);

    const handleAddAdditionalEmailRecipients = () => {
      setShouldAddAdditionalEmailRecipients((prevState) => !prevState);
    };

    const handleInputChange = (event) => {
      const input = event.target.value;

      if (input.includes(",") || input.includes(";") || input.includes(" ")) {
        const potentialEmails = input.split(/[\s,;]+/);
        const newEmails = [];
        potentialEmails.forEach((email) => {
          if (validator.isEmail(email)) {
            if (
              !addAdditionalEmailRecipients.includes(email) &&
              !newEmails.includes(email)
            ) {
              newEmails.push(email);
            } else {
              setEmailError(`The email ${email} is already added.`);
            }
          } else {
            setEmailError(`The email ${email} is not a valid email address.`);
          }
        });
        if (newEmails.length > 0) {
          setAddAdditionalEmailRecipients((prevEmails) => [
            ...prevEmails,
            ...newEmails,
          ]);
          setCurrentEmail("");
        }
      } else {
        setCurrentEmail(input);
      }
      setEmailError("");
    };

    const handleAdditionalEmailRecipients = (event) => {
      if (["Enter", ",", ";", " ", "Tab"].includes(event.key)) {
        event.preventDefault();
        const email = currentEmail.trim();
        if (validator.isEmail(email)) {
          if (!addAdditionalEmailRecipients.includes(email)) {
            setAddAdditionalEmailRecipients((prevEmails) => [
              ...prevEmails,
              email,
            ]);
            setCurrentEmail("");
          } else {
            setEmailError(`The email ${email} is already added.`);
          }
        } else {
          setEmailError(`The email ${email} is not a valid email address.`);
        }
      }
    };

    const handleOneAdditionalEmailRecipient = () => {
      if (currentEmail) {
        if (validator.isEmail(currentEmail)) {
          setAddAdditionalEmailRecipients((prevEmails) => {
            if (!prevEmails.includes(currentEmail)) {
              return [...prevEmails, currentEmail];
            } else {
              setEmailError(`The email ${currentEmail} is already added.`);
              return prevEmails;
            }
          });
        } else {
          setEmailError(
            `The email ${currentEmail} is not a valid email address.`
          );
        }
      }
      !emailError && setCurrentEmail('')
    };

    const handleDelete = (emailToDelete) => {
      setAddAdditionalEmailRecipients(
        addAdditionalEmailRecipients.filter((email) => email !== emailToDelete)
      );
    };

    const onCreateClick = async (confirmed = false) => {
      setIsCreatingDocument(true);
      try {
        const response = await post(
          "pdfdocumentation",
          {
            SLOrganization: selectedOrganization,
            SLProjectSpace: selectedProjectSpace,
            SLProject: selectedProjects.map((project) => project.value).join(),
            ConfluenceMainPage: "",
            confluence_page_url: "",
            configFiles: "",
            confirmed: confirmed || false,
            discardPipelines: {
              startsWith: startsWithFilter,
              contains: containsFilter,
            },
            additionalEmailRecipients: addAdditionalEmailRecipients
          },
          { timeout: 10 * 60 * 1000 }
        );

        if (response?.data?.limitExcited) {
          setIsErrorModalOpen(true);

          setErrorModalMessage(response.data.message);

          if (response.data.requestConfirmation) {
            setErrorModalAssets(response.data.assets);
          }

          return;
        }

        setGetExecutionIdFromResponse(response.data[0]);
        toast.success(response.data[1]);
        setShowModal(true);
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setIsCreatingDocument(false);
        setAddAdditionalEmailRecipients([]);
      } catch (error) {
        toast.error(error.response.data.name || "Something went wrong!");
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setIsCreatingDocument(false);
        setAddAdditionalEmailRecipients([]);
      }
    };

    const projectSpaceDisabled = projects.length === 0 || projects.length === 0;

    const projectDisabled = !selectedProjectSpace;

    const projectSpaceArray =
      projects.length > 0 ? Object.keys(projects[0]) : [];

    const projectSpaceOptions = projectSpaceArray.filter(
      (projectSpace) => projectSpace !== "confluenceStatus"
    );

    const projectOptions =
      projects.length > 0 && selectedProjectSpace
        ? projects[0][selectedProjectSpace].map((project) => ({
            value: project,
            label: project,
          }))
        : [];

    const onSelectProjectSpace = async (e) => {
      setSelectedProjectSpace(e.target.value);
      setSelectedProjects([]);
      // setProjectsWithConfigFiles([]);
      // setSelectedProjectsWithConfigFiles([]);
      // setSelectedConfigOptions([]);
      // setIncludeConfigFiles(false);
      // setStoreConfigFileLocationOnly(false);
    };

    const handleProjectChange = (e) => {
      setSelectedProjects(e);
    };

    // const handleCheckIncludeConfig = () => {
    //   setIncludeConfigFiles((prev) => !prev);
    // };

    // const handleCheckStoreFileLocation = () => {
    //   setStoreConfigFileLocationOnly((prev) => !prev);
    // };

    // const handleConfigSelectChange = (newValue) => {
    //   setSelectedConfigOptions(newValue);

    //   const newSelectedProjectsWithConfigFiles = [];

    //   newValue
    //     .map((elem) => elem.value.project)
    //     .reduce((acc, curr) => {
    //       if (!acc.includes(curr)) {
    //         acc.push(curr);
    //       }
    //       return acc;
    //     }, [])
    //     .forEach((project) =>
    //       newSelectedProjectsWithConfigFiles.push({
    //         project: project,
    //         files: [],
    //       })
    //     );

    //   newValue
    //     .map((elem) => elem.value)
    //     .forEach(({ project, file }) => {
    //       newSelectedProjectsWithConfigFiles
    //         .find((newProject) => newProject.project === project)
    //         ?.files.push(file);
    //     });

    //   setSelectedProjectsWithConfigFiles(newSelectedProjectsWithConfigFiles);
    // };

    if (noOrganization) {
      return (
        <p className="error text-center my-5 ">
          Please select valid organization or contact support team
        </p>
      );
    }

    return (
      <>
        {showSpinner ? (
          <Spinner />
        ) : (
          <div className="d-flex flex-column mt-5 mx-4">
            <div className="w-75">
              <h2 className={`${style.header}`}>
                Choose Your SnapLogic Project
              </h2>
              <p className={`${style.text}`}>
                Please choose your SnapLogic project space and one or multiple
                projects that you want to document.
              </p>
              <div className="d-flex justify-content-between align-items-end mt-5 mb-3">
                <Select
                  id="project-space"
                  size="lg"
                  options={projectSpaceOptions}
                  customClassNames={["custom_select"]}
                  defaultValue="Select Project Space"
                  label="Select Project Space*"
                  disabled={projectSpaceDisabled}
                  value={selectedProjectSpace}
                  onChange={(e) => onSelectProjectSpace(e)}
                  wrapperClasses={["custom_wrapper"]}
                />
                <Button
                  customClassNames={["icon_button"]}
                  onClick={onReloadClick}
                >
                  <ArrowClockwise />
                </Button>
                <MultiSelect
                  id="project"
                  options={projectOptions}
                  customClassNames={["custom_select"]}
                  label="Select Project*"
                  disabled={projectDisabled}
                  value={selectedProjects}
                  onChange={handleProjectChange}
                  wrapperClasses={["custom_wrapper"]}
                  placeholder="Select Project"
                />
              </div>

              {selectedProjects.length > 0 ? (
                <div className="w-100 d-flex flex-column mb-3 mt-4">
                  <PipelineNameFilter />

                  {/* This is commented out in case config files are added to pdf in the future */}
                  {/* <FormCheck
                    id="include-config-files"
                    type="checkbox"
                    label="Include config files"
                    checked={includeConfigFiles}
                    onChange={handleCheckIncludeConfig}
                  />

                  {includeConfigFiles ? (
                    <>
                      {isFetchingConfigFiles ? (
                        <div className="w-50">
                          <Spinner />
                        </div>
                      ) : (
                        <div className="ms-4 mt-3">
                          <MultiSelect
                            id="config-files"
                            options={configFileOptions}
                            customClassNames={["custom_select"]}
                            label="Files"
                            disabled={isFetchingConfigFiles}
                            value={selectedConfigOptions}
                            onChange={handleConfigSelectChange}
                            wrapperClasses={["custom_wrapper"]}
                            placeholder="Select JSON or EXPR files"
                          />

                          <div className="ms-4 mt-4">
                            <FormCheck
                              id="store-config-files-location-only"
                              type="checkbox"
                              label="Store file location only"
                              checked={storeConfigFileLocationOnly}
                              onChange={handleCheckStoreFileLocation}
                            />
                            <p className={`${style.text} ms-3`}>
                              *Do not store the file content, keep the file
                              location only.
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )} */}
                </div>
              ) : (
                <></>
              )}
              <div>
                <FormCheck
                  id="add-additional-email-recipients"
                  type="checkbox"
                  label="Add additional email recipients"
                  checked={shouldAddAdditionalEmailRecipients}
                  onChange={handleAddAdditionalEmailRecipients}
                />
                {!shouldAddAdditionalEmailRecipients ? (
                  <label style={{ color: "grey", fontSize: "12px" }}>
                    * By default only current user will receive email
                    notification
                  </label>
                ) : null}
              </div>
              {shouldAddAdditionalEmailRecipients ? (
                <>
                  <div className={`${style.emailInputContainer}`}>
                    <div className={`${style.emailChipsContainer}`}>
                      {addAdditionalEmailRecipients.map((email) => (
                        <div key={email} className={`${style.emailChip}`}>
                          {email}
                          <button
                            onClick={() => handleDelete(email)}
                            style={{ marginLeft: "5px" }}
                            className={`${style.emailDelete}`}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                      <input
                        id="email-recepients"
                        size="lg"
                        placeholder={
                          addAdditionalEmailRecipients.length === 0
                            ? "Email recipients"
                            : ""
                        }
                        value={currentEmail}
                        onChange={handleInputChange}
                        onKeyDown={handleAdditionalEmailRecipients}
                        onBlur={handleOneAdditionalEmailRecipient}
                        style={{ outline: "none", border: "none", padding: 5 }}
                        className={`${style.emailInputField}`}
                      />
                      {emailError && (
                        <div className={`${style.emailError}`}>
                          {emailError}
                        </div>
                      )}
                    </div>
                  </div>
                  <label style={{ color: "grey", fontSize: "12px" }}>
                    * Comma separated emails
                  </label>
                </>
              ) : null}

              {/* {projects.length > 0 && !projects[0].confluenceStatus ? (
                <p className="text-danger">
                  Please check your Confluence settings first
                </p>
              ) : null} */}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-end align-items-end mx-5 mt-3">
          <Button
            onClick={() => onCreateClick(false)}
            disabled={
              isCreatingDocument ||
              selectedProjectSpace === "" ||
              selectedProjects.length === 0 ||
              emailError
            }
          >
            Create
          </Button>
        </div>
      </>
    );
  }
);

export default PDFStep;
