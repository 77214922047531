import React from "react";
import style from "./index.module.css";

function ConfluenceSetup() {
  return (
    <div className="mt-4">
      <h6>Confluence setup</h6>
      <p>
        For setting up the confluence on the IWDG application you will need to
        populate the following four parameters:
      </p>
      <ul className={style["disc-list"]}>
        <li>Confluence URL</li>
        <li>Confluence Space Key</li>
        <li>Confluence Email</li>
        <li>Confluence Token</li>
      </ul>
      <p>
        Here is the confluence setup page on the IWDG application:{" "}
        <a
          href="https://pete-va.iwconnect.com/confluence"
          target="_blank"
          rel="noreferrer"
        >
          https://pete-va.iwconnect.com/confluence
        </a>
      </p>
      <img
        src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceSetup.png"
        alt="Set up confluence"
        className={style["info-page-image"]}
      />
      <p>
        Follow the step-by-step instructions on how to get the appropriate
        values for your confluence space.{" "}
      </p>
    </div>
  );
}

export default ConfluenceSetup;
