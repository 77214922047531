import React from 'react';
import { classLister } from '../../utils/style';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import style from './index.module.css';

const Button = ({
  id,
  className,
  customClassNames,
  children,
  disabled,
  type,
  size,
  variant,
  show,
  onClick,
  showSpinner,
  spinnerAnimation,
  spinnerSize,
  spinnerColor
}) => {
  const customClasses = classLister(style)(customClassNames);

  return (
    <button
      id={id}
      className={`${className || ''} ${show ? '' : 'invisible'} ${customClasses} btn ${style.primary}`}
      disabled={disabled}
      type={type}
      size={size}
      variant={variant}
      onClick={onClick}
    >
      {children}
      {
        showSpinner
          ? (
            <BootstrapSpinner
              animation={spinnerAnimation}
              role="status"
              size={spinnerSize}
              area-hidden="true"
              style={{ color: spinnerColor }}
            >
              <span className="visually-hidden">Loading...</span>
            </BootstrapSpinner>
          )
          : null
      }
    </button>
  );
};

Button.defaultProps = {
  customClassNames: [],
  show: true,
  showSpinner: false,
  spinnerAnimation: 'border',
  spinnerSize: 'sm',
  spinnerColor: 'white'
};

export default Button;
