import React from 'react'
import style from "./index.module.css";

const PrinciplesDataProtection = () => {
    return (
        <div className="mt-5">
            <h4>Principles for Personal Data Protection</h4>
            <p>Your personal information: </p>
            <p>
                <ul className={style["disc-list"]}>
                    <li>We <strong>process</strong> in accordance with the law, to a sufficient extent and in a transparent manner;</li>
                    <li>We <strong>collect</strong> only for purposes that we deem appropriate and, in the manner, explained to you in this statement;  </li>
                    <li>We <strong>use</strong> only on the way we inform you when we collect the data;  </li>
                    <li><strong>Ensure</strong> they are accurate and up to date</li>
                    <li>We <strong>keep</strong> only as much as the law requires, i.e. as much as we need to fulfill our legal obligations </li>
                    <li>We process in a way that ensures that they <strong>will not be used for another purpose for which you have not been informed</strong> or agreed (when possible and appropriate) and will not be lost or destroyed. </li>
                </ul>
            </p>
        </div>
    )
}

export default PrinciplesDataProtection;