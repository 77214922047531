import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

function ErrorModal({ isOpen, modalMessage, modalAssets, onClose, onConfirm }) {
  const [message, setMessage] = useState();
  const [assets, setAssets] = useState();

  useEffect(() => {
    setMessage(modalMessage);
    setAssets(modalAssets);
  }, [modalMessage, modalAssets]);

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="close" onClick={onClose}>
        Close
      </Button>
      {modalAssets ? (
        <Button id="create-new-documentation" onClick={onConfirm}>
          Confirm
        </Button>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <Modal
      id="error-document-modal"
      onHide={onClose}
      show={isOpen}
      modalButtons={modalButtons}
      backdrop="static"
    >
      <div>{message}</div>
      {assets ? (
        <ul>
          <li>Allowed assets: {assets.allowedAssets}</li>
          <li>Used assets: {assets.usedAssets}</li>
          <li>New assets : {assets.newAssets}</li>
        </ul>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default ErrorModal;
