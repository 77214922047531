import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import Button from "../../components/Button";
import Input from "../../components/Input";

const DashboardSearch = ({
  isSearching,
  projectName,
  setProjectName,
  userParam,
  setUserParam,
  executionID,
  setExecutionID,
  status,
  setStatus,
  startDateTime,
  setStartDateTime,
  isFetchingData,
  handleSearch,
  shouldClearAdvancedSearch,
  setShouldClearAdvancedSearch,
  advancedSearchApplied,
  setAdvancedSearchApplied,
  setIsSearching,
  callApi,
}) => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    if (shouldClearAdvancedSearch) {
      setStatus("");
      setStartDateTime("");
    }

    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onApplyClick = event => {
    if (status || startDateTime) {
      setAdvancedSearchApplied(true);
      setShouldClearAdvancedSearch(false);
      setShow(false);
      handleSearch(event);
      setErrorMessage("");
    } else {
      setAdvancedSearchApplied(false);
      setErrorMessage("Enter value in order to do advanced search");
    }
  };

  useEffect(() => {
    if (!projectName && !userParam && !executionID) {
      setIsSearching(false);
      callApi(true)
    }
  }, [projectName, userParam, executionID]);

  useEffect(() => {
    if (isSearching && (!projectName || !userParam || !executionID)) {
      callApi(true)
    }

  }, [projectName, executionID, userParam, isSearching])


  const clearAll = () => {
    setStatus("");
    setStartDateTime("");
    setExecutionID("")
    setProjectName("")
    setUserParam("")
    callApi(true);
    setAdvancedSearchApplied(false)
    setIsSearching(false);
    setShow(false);
  };

  return (
    <>
      <Container fluid className="px-0">
        <Row>
          <Col md={3} className="pe-0">
            <Input
              label="Project Name"
              id="Project Name"
              placeholder="Enter Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              onClick={() => setProjectName("")}
              showClearButton
            />
          </Col>
          <Col md={3} className="px-1">
            <Input
              label="User"
              placeholder="Enter User"
              id="User"
              value={userParam}
              onChange={(e) => setUserParam(e.target.value)}
              onClick={() => setUserParam("")}
              showClearButton
            />
          </Col>
          <Col md={3} className="px-0 pe-1">
            <Input
              label="Execution ID"
              id="Execution ID"
              placeholder="Enter Execution ID"
              value={executionID}
              onChange={(e) => setExecutionID(e.target.value)}
              onClick={() => setExecutionID("")}
              showClearButton
            />
          </Col>
          <Col md={1} className="d-grid align-self-center my-2 px-1">
            <Button disabled={isFetchingData} type="submit">
              Search
            </Button>
          </Col>
          <Col md={2} className="d-grid align-self-center my-2 px-0">
            <Button type="button" onClick={handleShow}>
              {advancedSearchApplied ? (
                <span className="font-weight-bold">&#x2713;</span>
              ) : (
                ""
              )}
              Advanced Search
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <FormLabel
                className="mb-1"
                style={{ fontSize: "14px", color: "#7a86a1" }}
              >
                Select Status{" "}
              </FormLabel>
              <FormSelect
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Status</option>
                <option value="1">Completed</option>
                <option value="0">In progress</option>
                <option value="2">Failed </option>
              </FormSelect>
            </Col>
            <Col md={6}>
              <Input
                label="Start Date Time"
                id="Start-date-Time"
                placeholder="Start Date Time"
                type="date"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
                max={new Date().toISOString().substring(0, 10)}
              />
            </Col>
          </Row>
          <Row>
            {errorMessage && (
              <div className="advanced_search_error">{errorMessage}</div>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <Button
              variant="secondary"
              onClick={clearAll}
              disabled={!startDateTime && !status}
            >
              Clear Filter
            </Button>
            <div>
              <Button
                onClick={(e) => onApplyClick(e)}
                className="me-2"
              >
                Search
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardSearch;
