import React from "react";
import InfoPageHeader from "../../components/InfoPages/Header";
import PrivacyNoticeContent from "../../components/PrivacyNoticeContent";

const PrivacyNotice = () => {
  return (
    <div className="w-75 mx-auto">
      <InfoPageHeader />
      <PrivacyNoticeContent />
    </div>
  );
};

export default PrivacyNotice;
