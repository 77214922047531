import React from "react";
import style from "./index.module.css";

function Cookies() {
  return (
    <div className={style["cookies-container"] + " mt-5"}>
      <h4>Cookies</h4>
      <p>The Web application of our Service uses the following cookies:</p>
      <h5>Essential Cookies</h5>
      <p>
        These cookies are necessary for the website to function and cannot be
        switched off in our systems.
      </p>
      <div className={style["cookies-table-container"]}>
        <table className={style["cookies-table"]}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Provider</th>
              <th>Purpose</th>
              <th>Expiration Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>User basic login</td>
              <td>Pete</td>
              <td>
                To maintain the user's session state and authentication details.
              </td>
              <td>Expires at the end of the session</td>
              <td>Essential</td>
            </tr>
            <tr>
              <td>ExpiresIn</td>
              <td>Pete</td>
              <td>
                Created by the application once the user logs into the Service.
                It holds the time of the session
              </td>
              <td>Expires at the end of the session</td>
              <td>Essential</td>
            </tr>
            <tr>
              <td>fpc</td>
              <td>Microsoft</td>
              <td>
                Stands for "First-Party Cookie." Used to track user sessions or
                preferences on the site that sets the cookie, ensuring a
                consistent user experience.
              </td>
              <td>Approximately 1 month</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>CCState</td>
              <td>Microsoft</td>
              <td>Manages the user's consent state for cookies.</td>
              <td>10 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>ESTSAUTHPERSISTENT</td>
              <td>Microsoft</td>
              <td>Keeps users authenticated across multiple sessions. </td>
              <td>Approximately 90 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>wlidperf</td>
              <td>Microsoft</td>
              <td>
                Used to collect performance information for Windows Live ID
                authentication.
              </td>
              <td>Approximately 1 year and 8 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>SignInStateCookie</td>
              <td>Microsoft</td>
              <td>
                Helps maintain state across multiple requests within a single
                session.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>ESTSAUTH</td>
              <td>Microsoft</td>
              <td>
                Used for maintaining the authentication state during the OAuth
                login process.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>brcap</td>
              <td>Microsoft</td>
              <td>
                Used for managing the rate of requests to the server to prevent
                flooding
              </td>
              <td>Approximately 1 year and 10 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>x-ms-gateway-slice</td>
              <td>Microsoft</td>
              <td>
                Used for load balancing to ensure requests are handled
                efficiently by the Microsoft services.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>ESTSAUTHLIGHT</td>
              <td>Microsoft</td>
              <td>
                Lightweight version of ESTSAUTH used for single sign-on across
                Microsoft services.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>buid</td>
              <td>Microsoft</td>
              <td>
                Used for uniquely identifying the device or browser to enhance
                security during authentication.
              </td>
              <td>Approximately 1 month</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>ESTSSSOTILES</td>
              <td>Microsoft</td>
              <td>
                Serves to manage session state and maintain user authentication
                as part of the single sign-on (SSO) process across various
                Microsoft applications and services
              </td>
              <td>Approximately 1 year and 15 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>MSFPC</td>
              <td>Microsoft</td>
              <td>
                Related to Microsoft's tracking and personalization features
              </td>
              <td>Approximately 1 month and 15 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>MicrosoftApplications TelemetryDeviceId</td>
              <td>Microsoft</td>
              <td>
                Helps in identifying a device uniquely, allowing Microsoft to
                gather data about how their applications and services are being
                used across different devices.
              </td>
              <td>Approximately 1 year</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>Microsoft</td>
              <td>
                Used by Google Analytics to distinguish users by assigning a
                randomly generated number as a client identifier.
              </td>
              <td>Approximately 1 year and 1 month</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>_ga_BNNQ6GHLNL</td>
              <td>Microsoft</td>
              <td>
                These are variant forms of the standard _ga cookie and serve a
                similar purpose. They are set when you use the Google Analytics
                service with additional configurations or custom settings, like
                when implementing multiple tracking codes for enhanced tracking
                or data collection purposes.
              </td>
              <td>Approximately 9 months and 14 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>_ga_Z5CQR51RWT</td>
              <td>Microsoft</td>
              <td>
                These are variant forms of the standard _ga cookie and serve a
                similar purpose. They are set when you use the Google Analytics
                service with additional configurations or custom settings, like
                when implementing multiple tracking codes for enhanced tracking
                or data collection purposes.
              </td>
              <td>Approximately 1 year and 1 month</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>esctx and esctx-IPwgNRJXDuE</td>
              <td>Microsoft</td>
              <td>
                These cookies are related to Microsoft Azure Active Directory
                and are used for security purposes. They are likely involved in
                maintaining context information for authentication and may be
                used to prevent cross-site request forgery (CSRF) attacks.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>stsservicecookie</td>
              <td>Microsoft</td>
              <td>
                Used in the authentication process to cache tokens and improve
                performance and security during login processes or while
                accessing Microsoft services.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>AADSSO</td>
              <td>Microsoft</td>
              <td>
                Used to facilitate Single Sign-On (SSO) processes for
                applications that use Azure Active Directory for authentication.
              </td>
              <td>Session</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
            <tr>
              <td>AADSSOTILES</td>
              <td>Microsoft</td>
              <td>
                Related to the user interface elements or "tiles" that represent
                different applications or services within the Azure AD portal or
                other Microsoft services that support SSO
              </td>
              <td>Approximately 1 year and 15 days</td>
              <td>Third-Party Cookie, Essential</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style["cookies-container"] + " mt-5"}>
        <h5>Functional Cookies</h5>
        <p>
          We utilize reCAPTCHA cookies to enhance the security of our website
          and protect against automated abuse such as spam and bots. These
          cookies enable the reCAPTCHA mechanism, which verifies that user
          interactions on our site are genuine and being made by real people
          rather than automated programs.If these functional cookies are
          disabled, our website's signup form will not be displayed, as
          reCAPTCHA is an integral part of our user verification process during
          registration. By keeping these cookies enabled, you assist us in
          providing a secure and user-friendly registration process.
        </p>
        <div className={style["cookies-table-container"]}>
          <table className={style["cookies-table"]}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Provider</th>
                <th>Purpose</th>
                <th>Expiration Date</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1P_JAR</td>
                <td>Google</td>
                <td>
                  Used to gather website statistics and track conversion rates
                </td>
                <td>Approximately 6 months</td>
                <td>Third-Party Cookie, Functional</td>
              </tr>
              <tr>
                <td>AEC</td>
                <td>Google</td>
                <td>
                  Used to ensure that requests within a browsing session are
                  made by the user, and not by other sites.
                </td>
                <td>Approximately 6 months</td>
                <td>Third-Party Cookie, Functional</td>
              </tr>
              <tr>
                <td>NID</td>
                <td>Google</td>
                <td>
                  Used by Google to store user preferences and other information
                  when visiting pages that contain Google services, such as
                  Google Maps, Google Captcha, or Google Search embedded on
                  third-party websites
                </td>
                <td>Approximately 6 months</td>
                <td>Third-Party Cookie, Functional</td>
              </tr>
              <tr>
                <td>_GRECAPTCHA</td>
                <td>Google</td>
                <td>
                  The _GRECAPTCHA cookie is a part of Google’s reCAPTCHA
                  service, which is a security measure used to protect websites
                  from spam and abuse. This service helps to verify that a human
                  is making the request and not a bot.
                </td>
                <td>Approximately 1 month</td>
                <td>Third-Party Cookie, Functional</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
