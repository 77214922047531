import React from "react";
import Sidebar from "./Sidebar";
import CenteredTitle from "../CenteredTitle";

function InfoPage({ title, subtitle, children }) {
  return (
    <div
      className="w-100 d-flex flex-column bg-light mt-3 px-4 pb-5"
      style={{ borderRadius: "5px" }}
    >
      <CenteredTitle title={title} subtitle={subtitle} />
      <div className="d-flex" style={{ gap: "16px" }}>
        <Sidebar />
        {children}
      </div>
    </div>
  );
}

export default InfoPage;
