import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { get } from "../../utils/api";
import Spinner from "../../components/Spinner/index";
import { toast } from "react-toastify";
import Title from "../../components/UI/Title";

const Faq = () => {
  const [isFetchingFaq, setIsFetchingFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    const fetchFaq = async () => {
      setIsFetchingFaq(true);
      try {
        const response = await get("user/faq");

        if (!mountedRef.current) return null;
        setFaqs(response.data);
      } catch (error) {
        if (!mountedRef.current) return null;

          toast.error(error.response.data.name);
      } finally {
        setIsFetchingFaq(false);
      }
    };

    fetchFaq();

    return () => {
      mountedRef.current = false;
    };
  }, [setFaqs]);

  return (
    <>
      <Title title="FAQ" />
      {isFetchingFaq ? (
        <Spinner />
      ) : (
        <Accordion defaultActiveKey={[0]} alwaysOpen>
          {faqs &&
            faqs.map((faq) => (
              <Accordion.Item
                key={faq.questionId}
                eventKey={[faq.questionId]}
                className="mb-4 bg-light"
              >
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body style={{ marginLeft: "20px" }}>
                  <p className="mb-0">{faq.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      )}
    </>
  );
};

export default Faq;
