import React, { useState } from "react";
import style from "./index.module.css";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

function ConfluenceConnection() {
  const [isConfluenceConnectionOpen, setIsConfluenceConnection] =
    useState(false);

  const toggleConfluenceConnection = () => {
    setIsConfluenceConnection(!isConfluenceConnectionOpen);
  };

  return (
    <div className="mt-3">
      <Row>
      <Col md={11} className="d-flex align-items-center">
          <button 
            onClick={toggleConfluenceConnection} 
            className="btn btn-text p-0" 
            style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', font: 'inherit', cursor: 'pointer' }}
          >
          <h4>Confluence Connection</h4>
          </button>
        </Col>
        <Col
          md={1}
          onClick={toggleConfluenceConnection}
          className="text-center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "-1rem" }}>
            {isConfluenceConnectionOpen ? <ChevronDown /> : <ChevronUp />}
          </div>
        </Col>
      </Row>
      {isConfluenceConnectionOpen ? (
        <Row>
          <p>
            To connect to Confluence, you need a valid Confluence organization
            and an account with access to your Confluence organization. Create a
            Confluence access token and enter the following details in Pete's
            web application:
          </p>
          <p>
            <strong>Confluence Information we collect and store</strong>
          </p>
          <ul className={style["disc-list"]}>
            <li>Confluence URL</li>
            <li>Confluence Space Key</li>
            <li>Confluence Email</li>
            <li>Confluence Token (encrypted)</li>
          </ul>
          <p>These details are securely stored in Pete's database.</p>
          <p>
            Please note that the documentation that will be created in
            Confluence will be created by the Confluence user that has been
            setup at the time of the documentation creation. You can also change
            this user in the Confluence settings of the application but please
            be aware that it will overwrite the previously stored settings, to
            be more precise, the new settings entered will be stored instead of
            the old setting.
          </p>
        </Row>
      ) : null}
    </div>
  );
}

export default ConfluenceConnection;
