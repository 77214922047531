import React from "react";
import style from "./index.module.css";
import DontHaveConfluence from "./DontHaveConfluence";
import ConfluenceSetup from "./ConfluenceSetup";
import ConfluenceURL from "./ConfluenceURL";
import ConfluenceSpaceKey from "./ConfluenceSpaceKey";
import ConfluenceEmail from "./ConfluenceEmail";
import ConfluenceToken from "./ConfluenceToken";

function Confluence() {
  return (
    <div className="mt-5">
      <h4>Confluence</h4>
      <ul className={style["list-none"]}>
        <li>
          <DontHaveConfluence />
        </li>
        <li>
          <ConfluenceSetup />
        </li>
        <li>
          <ConfluenceURL />
        </li>
        <li>
          <ConfluenceSpaceKey />
        </li>
        <li>
          <ConfluenceEmail />
        </li>
        <li>
          <ConfluenceToken />
        </li>
      </ul>
    </div>
  );
}

export default Confluence;
