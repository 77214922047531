import React from 'react';
import Title from '../../components/UI/Title';
import { Placeholder } from 'react-bootstrap';

const UserProfile = ({ userInfo, isLoading }) => {
  return (
    <>
      <Title title="User Profile" />
      {isLoading ? (
        <>
          <Placeholder animation="glow">
            <Placeholder xs={6} size="xs" bg="secondary" />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={12} size="xs" bg="secondary" />
          </Placeholder>
        </>
      ) : (
        <>
          <p className="">
            {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
          </p>
          <p>{userInfo && userInfo.email}</p>
          <hr className="bg-secondary" />
        </>
      )}
    </>
  );
};

export default UserProfile;
