import React from "react";

function HostingLocation() {
  return (
    <div className="mt-5">
      <h4>Hosting Location</h4>
      <p>
        Pete is using Azure Services and is hosted in the Azure West Europe
        region.
      </p>
    </div>
  );
}

export default HostingLocation;
