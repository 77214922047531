import React from "react";
import UsingPeteMail from "./UsingPeteMail";
import UsingOrgMail from "./UsingOrgMail";
import style from "./index.module.css";

function SnapLogic() {
  return (
    <div className="mt-5">
      <h4>SnapLogic</h4>
      <p>
        For the SnapLogic setup you will need a valid SnapLogic account added to
        your SnapLogic organization. There are two options how can you achieve
        this:
      </p>

      <ul className={style["list-none"]}>
        <li>
          <UsingPeteMail />
        </li>
        <li>
          <UsingOrgMail />
        </li>
      </ul>
    </div>
  );
}

export default SnapLogic;
