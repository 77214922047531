import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormText,
  OverlayTrigger,
  Tooltip,
  ListGroup
} from 'react-bootstrap';
import { classLister } from '../../utils/style';
import style from './index.module.css';
import { Eye, EyeSlash, X } from 'react-bootstrap-icons';

const Input = ({
  id,
  className,
  customClassNames,
  wrapperClasses,
  type,
  disabled,
  size,
  value,
  onChange,
  label,
  placeholder,
  name,
  endAdornment,
  textarea,
  error,
  toolTipInfo,
  max,
  showPasswordToggle,
  showClearButton,
  onClick,
  autoComplete
}) => {

  const [showPassword, setShowPassword] = useState(false);
  const customClasses = classLister(style)(customClassNames);
  const customWrapperClasses = classLister(style)(wrapperClasses);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormGroup className={`mb-3 ${customWrapperClasses} mt-0 mb-4`} controlId={id}>
      {label && <FormLabel className={`${style.custom_label} mb-1`}>{label}</FormLabel>}
      <div className={`d-flex align-items-center ${style.inputGroup}`}>
        <FormControl
          className={`${className || ''} ${customClasses} ${showPasswordToggle ? style.inputWithToggle : ''} ${showClearButton ? style.inputWithClear : ''}`}
          as={`${textarea ? 'textarea' : 'input'}`}
          type={showPasswordToggle && showPassword ? 'text' : type}
          placeholder={placeholder}
          disabled={disabled}
          size={size}
          value={value}
          onChange={onChange}
          name={name}
          autoComplete={autoComplete}
          max={max}
        />
        {showPasswordToggle && (
          <div className={style.eyeIcon} onClick={toggleShowPassword}>
            {showPassword ? <EyeSlash /> : <Eye />}
          </div>
        )}
        {showClearButton && value && (
          <div className={`${style.clearIcon} position-absolute`} onClick={onClick}>
            <X />
          </div>
        )}
        {endAdornment && (
          <OverlayTrigger
            placement="left"
            // delay={{ show: 250, hide: 110400 }}
            overlay={
              <Tooltip id={`tooltip`}>
                <ListGroup>
                  {toolTipInfo.map((info) => (
                    <ListGroup.Item key={info}>{info}</ListGroup.Item>
                  ))}
                </ListGroup>
              </Tooltip>
            }
          >
            <img src={endAdornment} className={`${style.icon} ${style[size]}`} alt="icon" />
          </OverlayTrigger>
        )}
      </div>
      {error && <FormText className="text-danger">{error}</FormText>}
    </FormGroup>
  );
};

Input.defaultProps = {
  label: '',
  type: 'string',
  disabled: false,
  customClassNames: [],
  wrapperClasses: [],
  placeholder: '',
  showPasswordToggle: false,
  showClearButton: false,
  autoComplete: "off"
};

export default Input;
