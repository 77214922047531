import React from "react";
import style from "./index.module.css";

function InformationWeCollectAndStore() {
  return (
    <div className="mt-5">
      <h4>Information we collect and store</h4>
      <p>
        We collect only Personal Data that is necessary for operating the
        Service. We collect the following types of data:
      </p>
      <p>
        <strong>User Information from the Service</strong>
        <ul className={style["disc-list"]}>
          <li>First Name </li>
          <li>Last Name</li>
          <li>Email</li>
          <li>Password (hashed)</li>
          <li>Optional notes</li>
        </ul>
      </p>
    </div>
  );
}

export default InformationWeCollectAndStore;
