import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import ContactUsForm from "./ContactUsForm";
import ContactUsCard from "./ContactUsCard";

const Contact = () => {
  return (
    <Container as={"main"} fluid className="px-5">
      <Row>
        <Col sm="12" lg="6" className="mb-sm-4 mb-lg-0">
          <ContactUsForm />
        </Col>
        <Col sm="12" lg="6">
          <Row className="h-100">
            <Col sm="6" lg="6" className="d-flex justify-content-end align-items-center">
              <ContactUsCard title="PHONE NUMBER" content="+389 47-241-914" Icon={TelephoneFill} />
            </Col>
            <Col sm="6" lg="6" className="d-flex justify-content-end align-items-center">
              <ContactUsCard title="EMAIL" content="documentation@iwdg.mk" Icon={EnvelopeFill} />
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Contact;
