import React from "react";

function DataRetention() {
  return (
    <div className="mt-5">
      <h4>Data retention</h4>
      <p>
        Pete retains the Personal Data we receive as described in this Privacy Notice for as long as you use our Service or as necessary to fulfill the purpose(s) for which it was collected, provide our Service, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.       </p>
    </div>
  );
}

export default DataRetention;
