import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import "./CookieSettingsModal.css";
import { Col, Row } from "react-bootstrap";

const CookieSettingsModal = ({ onClose, onAccept }) => {
  const [isFunctionalCookiesOpen, setIsFunctionalCookiesOpen] = useState(true);
  const [functionalCookiesEnabled, setFunctionalCookiesEnabled] =
    useState(true);

  const toggleFunctionalCookies = () => {
    setIsFunctionalCookiesOpen(!isFunctionalCookiesOpen);
  };

  const toggleFunctionalCookiesEnabled = () => {
    setFunctionalCookiesEnabled(!functionalCookiesEnabled);
  };

  return (
    <div className="cookie-settings-modal">
      <span className="close-btn" onClick={onClose}>
        &times;
      </span>
      <h2 style={{ color: "", fontFamily: "teleNeo-regular" }}>
        COOKIE SETTINGS
      </h2>
      <p>
        We use cookies and similar tools and technologies to provide an optimal
        user experience when using the website. Some cookies are necessary for
        the website to function properly.
      </p>
      <div className="cookie-section-title">
        <h2 style={{ fontFamily: "teleNeo-regular", color: "gray" }}>
          Necessary cookies
        </h2>
        <div>
          <label className="switch">
            <input type="checkbox" checked style={{ margin: "inherit" }} />
            <span className="slider round"></span>
          </label>
          <span style={{ marginLeft: "10px" }}>Mandatory</span>
        </div>
      </div>
      <div>
        <p>
          These necessary cookies are essential for the proper functioning of
          the website's basic features. They streamline the sign-in process and
          enable secure Microsoft account authentication. These cookies are set in response to
          actions made by you which amount to a request for services, such as
          setting your privacy preferences or filling in forms.
        </p>
      </div>
      <Row className="align-items-center" style={{ cursor: "pointer" }}>
        <Col
          md={1}
          onClick={toggleFunctionalCookies}
          className="text-center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "-1rem" }}>
            {isFunctionalCookiesOpen ? <ChevronDown /> : <ChevronUp />}
          </div>
        </Col>
        <Col md={9} onClick={toggleFunctionalCookies}>
          <h2 style={{ fontFamily: "teleNeo-regular", color: "gray" }}>
            Functional cookies
          </h2>
        </Col>
        <Col md={2}>
          <label className="switch">
            <input
              type="checkbox"
              style={{ margin: "inherit" }}
              checked={functionalCookiesEnabled}
              onChange={toggleFunctionalCookiesEnabled}
            />
            <span className="slider round" style={{ cursor: "default" }}></span>
          </label>
        </Col>
      </Row>
      {isFunctionalCookiesOpen && (
        <div className="cookie-section-content">
          <p>
            We utilize reCAPTCHA cookies to enhance the security of our website
            and protect against automated abuse such as spam and bots. These
            cookies enable the reCAPTCHA mechanism, which verifies that user
            interactions on our site are genuine and being made by real people
            rather than automated programs.If these functional cookies are
            disabled, our website's signup form will not be displayed, as
            reCAPTCHA is an integral part of our user verification process
            during registration. By keeping these cookies enabled, you assist us
            in providing a secure and user-friendly registration process.
          </p>
        </div>
      )}

      <button onClick={() => onAccept(functionalCookiesEnabled)}>Accept</button>
    </div>
  );
};

export default CookieSettingsModal;
