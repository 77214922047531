import React from "react";
import LogoImg from "../../../assets/images/IWDGPeteLogo.png";
import styles from "./logo.module.css";

const Logo = () => {
  return (
    <div className={styles.logo_wrapper}>
      <img src={LogoImg} alt="⋮IWConnect" className={styles.logo} />
    </div>
  );
};

export default Logo;
