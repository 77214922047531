import React from "react";

function DontHaveConfluence() {
  return (
    <div className="mt-4">
      <h6>What if I don’t have Atlassian Confluence and I want to try Pete using Confleunce?</h6>
      <p>
        Pete’s team is willing to create free confluence for you that you can
        use and manage (you will be admin) and the maximum allowed users on that
        Confluence instance is 10.
      </p>
    </div>
  );
}

export default DontHaveConfluence;
