export const PAGES = [
  {
    path: '/new',
    title: 'New Document',
  },
  {
    path: '/dashboard',
    title: 'My Dashboard',
  },
  {
    path: '/confluence',
    title: 'Confluence',
  },
  {
    path: '/summary',
    title: 'Organization Summary',
  },
  {
    path: '/profile',
    title: 'My Profile',
  },
  {
    path: '/users',
    title: 'Users'
  },
  {
    path: '/help',
    title: 'Help',
  },
  {
    path: '/contact',
    title: 'Contact Us',
  }
];
