import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { IdleTimerProvider } from "react-idle-timer";
import Cookies from "universal-cookie";
import routes from "./routes";
import { logoutAPI } from "../../service/auth.service";
import { useUserRoleContext } from "../../contexts/UserRoleContext";
import { useAuth } from "../../contexts/AuthContext";

const cookies = new Cookies();

const AppRoutes = () => {
  const navigate = useNavigate();
  const { userRole } = useUserRoleContext();
  const expiresIn = cookies.get("expiresIn") || "3600";
  const { isAuthenticated } = useAuth();

  const onIdle = () => {
    const authMethod = localStorage.getItem("authMethod")
    if(authMethod !== 'OAUTH2') {
      logoutAPI();
      navigate("/login");
    }
  };
  
  const isUserAuthenticated = () => {
    const authMethod = localStorage.getItem("authMethod");
    if (authMethod === 'BASIC') {
      const user = cookies.get("user");
      sessionStorage.setItem('userAuthenticated', Boolean(user?.token))
      return Boolean(user?.token)
    } else {
      sessionStorage.setItem('userAuthenticated', isAuthenticated)
      return isAuthenticated;
    }
  };
  
  const userAuthenticated = isUserAuthenticated();

  return (
    <IdleTimerProvider timeout={+expiresIn * 60} onIdle={onIdle}>
      <Routes>
        {routes.map(({ component, path, isProtected }) => {

          return (
            <Route
              key={path}
              path={path}
              element={
                isProtected && !userAuthenticated ? (
                  <Navigate to={"/login"} />
                ) : userAuthenticated && (path === "/" || path === "/login") ? (
                  <Navigate to={"/new"} />
                ) : userAuthenticated && userRole === 1 && path === "/users" ? (
                    <Navigate to={"/new"} />
                  )
                   : (
                    component
                  )
              }
            />
          );
        })}
      </Routes>
    </IdleTimerProvider>
  );
};

export default AppRoutes;
