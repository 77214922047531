import React, { useState } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Row } from "react-bootstrap";
import Logo from "./UI/Logo/Logo";
import Spinner from "./Spinner";
import AuthContainer from "../containers/Auth";
import Input from "./Input";
import Button from "./Button";

import { POST_RESET_PASSWORD } from "../constants/authConstants";
import { get } from "../utils/api";

const ForgotPassword = () => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleResetPasswordFormSubmit = async (event) => {
    event.preventDefault();

    if (!validator.isEmail(email)) {
      setEmailError("Enter valid Email!");
      return;
    } else {
      setEmailError("");
    }

    const apiUrl = `${POST_RESET_PASSWORD}?user=${email}`;

    try {
      setIsSendingEmail(true);
      const response = await get(apiUrl);
      if (response.status === 200) {
        setEmail("");
        toast.success(response.data);
      }
    } catch (error) {
      setIsSendingEmail(false);
      toast.error(error.response.data.name);
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <>
      {isSendingEmail && (
        <div className="login-overlay">
          <Spinner />
        </div>
      )}
      <AuthContainer>
        {/* <Row>
          <Logo />
        </Row> */}
        <h3 className="display-4 my-4">Forgot Password</h3>
        <form onSubmit={handleResetPasswordFormSubmit} noValidate={true}>
          <Input
            id="inputEmail"
            type="email"
            placeholder="Enter Valid Email"
            className="form-control px-4"
            style={{ border: "1px solid #ced4da75" }}
            name="email"
            value={email}
            label="Your Email"
            onChange={handleEmailChange}
            error={emailError}
          />
          <Button type="submit" className="w-100">
            Forgot Password
          </Button>
          <Button className="w-100 mt-3" onClick={() => navigate("/login")}>
            Return To Login
          </Button>
        </form>
      </AuthContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ForgotPassword;
