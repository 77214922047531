import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Spinner, Stack } from "react-bootstrap";
import Input from "../../components/Input";
import InfoIcn from "../../assets/images/info.png";
import Button from "../../components/Button";
import { formatUrl, get, post } from "../../utils/api";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import {
  CONFLUENCE_GET_API,
  CONFLUENCE_POST_API,
} from "../../constants/modulesApisConstant";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/Modal";

function Confluence() {
  const [spinner, setSpinner] = useState(false);
  const [noData, setNoData] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const selectedOrganization = useSelectedOrganization();
  const [showModal, setShowModal] = useState(false);
  const [confulenceObj, setConfluenceObj] = useState({
    isAdmin: false,
    url: "",
    key: "",
    email: "",
    token: "",
  });

  const callApi = async () => {
    const url = formatUrl("user/profile", {
      organizationName: selectedOrganization,
    });
    const responseProfile = await get(url);
    setUserRole(responseProfile.data[0].userRole);
  };

  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization]);

  const confluenceObjectHandler = (e) => {
    setConfluenceObj((previous) => ({
      ...previous,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let isCancelled = false;

    const callApi = async () => {
      setSpinner(true);
      setNoData(false);
      let newConfluenceObj = {
        url: "",
        key: "",
        email: "",
        token: "",
      };

      try {
        const response = await get(
          `${CONFLUENCE_GET_API}${selectedOrganization}`
        );

        if (!response) {
          setConfluenceObj((prev) => ({ ...prev, isAdmin: true }));
          return;
        }

        if (response.data.confluenceURL === null) {
          setNoData(true);
          setConfluenceObj(newConfluenceObj);
        } else {
          newConfluenceObj = {
            url: response.data.confluenceURL,
            key: response.data.confluenceSpaceKey,
            email: response.data.confluenceUser,
            token: response.data.confluenceToken,
            isAdmin: response.data.isAdmin,
          };

          if (!isCancelled) {
            setConfluenceObj(newConfluenceObj);
          }
        }
      } catch (error) {
        if (!isCancelled) {
          toast.error(error.response.data.name);
        }
      } finally {
        setSpinner(false);
      }
    };

    if (selectedOrganization) {
      callApi();
    }

    return () => {
      isCancelled = true;
    };
  }, [selectedOrganization]);

  const deleteConfluenceObj = async (e) => {
    if (!confulenceObj.isAdmin) {
      return;
    }
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await post(CONFLUENCE_POST_API, {
        organization: selectedOrganization,
        confluenceURL: "Please Change",
        confluenceSpaceKey: "Please Change",
        confluenceUser: "Please Change",
        confluenceToken: "Please Change",
        isAdmin: true
      });

      let newConfluenceObj = {
        url: "",
        key: "",
        email: "",
        token: "",
        isAdmin: true
      };

      setConfluenceObj(newConfluenceObj);
      setShowModal(false)
      toast.success("Confluence settings are successfully deleted!");
    } catch (error) {
      toast.error(error.response.data.name || "Delete confluence error!");
    } finally {
      setSpinner(false);
    }
  };

  const updateConfluenceObj = async (e) => {
    if (!confulenceObj.isAdmin) {
      return;
    }
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await post(CONFLUENCE_POST_API, {
        organization: selectedOrganization,
        confluenceURL: confulenceObj.url,
        confluenceSpaceKey: confulenceObj.key,
        confluenceUser: confulenceObj.email,
        confluenceToken: confulenceObj.token,
      });
      setNoData(false);
      toast.success(
        response.statusText + ".\nPlease refresh New Document page." ||
          "Confluence settings are successfully updated!"
      );
    } catch (error) {
      toast.error(error.response.data.name || "Update confluence error!");
    } finally {
      setSpinner(false);
    }
  };

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="cancelButton" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
      <Button
        id="create-new-organization-summary"
        onClick={deleteConfluenceObj}
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      <form className="px-5">
        {noData ? (
          <p>No data for selected organization: {selectedOrganization}.</p>
        ) : null}
        <Input
          disabled={spinner || !confulenceObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Confluence URL"
          label="Confluence URL"
          name="url"
          id="url"
          value={confulenceObj.url}
          onChange={confluenceObjectHandler}
          endAdornment={InfoIcn}
          toolTipInfo={[
            "If you're viewing the dashboard, the site URL is everything that comes before /index-action or /# (for example, everything before /#popular, /#recently-worked)",
            "If you're viewing a page or blog post, the site URL is everything that comes before display or pages",
          ]}
        />
        <Input
          disabled={spinner || !confulenceObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Confluence Space Key"
          label="Confluence Space Key"
          name="key"
          id="key"
          value={confulenceObj.key}
          onChange={confluenceObjectHandler}
          endAdornment={InfoIcn}
          toolTipInfo={[
            "You can then navigate directly to the space using a URL like this http://<yoursite>/display/snaplogic",
          ]}
        />
        <Input
          disabled={spinner || !confulenceObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Confluence Email"
          label="Confluence Email"
          name="email"
          id="email"
          value={confulenceObj.email}
          onChange={confluenceObjectHandler}
          endAdornment={InfoIcn}
          toolTipInfo={["Profile ( Contact > Email )"]}
        />
        {userRole === 2 ? (
          <>
            <Input
              disabled={spinner}
              type="text"
              placeholder="Enter Confluence Token"
              label="Confluence Token"
              name="token"
              id="token"
              value={confulenceObj.token}
              onChange={confluenceObjectHandler}
              endAdornment={InfoIcn}
              toolTipInfo={[
                "Log into Jira Software or Confluence and click your profile image.",
                "Open Profile.",
                "Click Manage Your Profile.",
                "Navigate to Security.",
                "Scroll down a little and click Create and manage API tokens.",
                "Click Create API token.",
                "Enter a Label and click Create.",
              ]}
            />

            <div className="d-flex justify-content-between align-items-end mx-1">
              <Stack direction="horizontal">
                <Button
                  disabled={spinner}
                  type="submit"
                  size="lg"
                  onClick={updateConfluenceObj}
                >
                  Save{" "}
                </Button>
                {spinner ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="mx-4"
                  />
                ) : null}
              </Stack>
              <Button disabled={spinner || !confulenceObj.email || !confulenceObj.url || !confulenceObj.key || !confulenceObj.token} type="button" size="lg" onClick={() => setShowModal(true)} className="mx-5">
                Delete
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title=""
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>Are you sure you want to delete the settings from the system ?</div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Confluence;
