import React from 'react';
import { TabContainer, TabContent, TabPane, Nav } from 'react-bootstrap';
import Tab from './Tab';

const Tabs = ({
  id,
  className,
  children,
  activeKey,
  onSelect,
  variant,
  tabs
}) => {
  return (
    <TabContainer id={id} activeKey={activeKey} onSelect={onSelect} className={className}>
      <Nav variant={variant}>
        {
          tabs.map((tab) => (
            <Tab eventKey={tab.eventKey} key={tab.eventKey}>{tab.value}</Tab>
          ))
        }
      </Nav>
      <TabContent>
        {
          children.map((child, index) => (
            <TabPane eventKey={tabs[index].eventKey} key={tabs[index].eventKey}>
              {child}
            </TabPane>
          ))
        }
      </TabContent>
    </TabContainer>
  )
};

export default Tabs;
