import React from "react";
import style from "./index.module.css";
import appRegistration01 from "../../../assets/images/appRegistration01.png";
import appRegistration02 from "../../../assets/images/AppRegistration02.png";
import appRegistration03 from "../../../assets/images/AppRegistration03.png";
import appRegistration04 from "../../../assets/images/AppRegistration04.png";

function CreateAppRegistration() {
  return (
    <div className={style.container}>
      <h4>Create the App Registration within Entra</h4>
      <ol className={style["numbered-list"]}>
        <li>
          While logged in as a tenant admin for the client’s organization, log
          in to{" "}
          <a href="https://portal.azure.com/" target="_blank" rel="noreferrer">
            portal.azure.com
          </a>{" "}
        </li>
        <li>
          Navigate to <strong>App Registrations</strong>.
        </li>
        <li>
          On the top Left of the page, create a{" "}
          <strong>New Registration</strong>.
          <ul className={style["disc-list"]}>
            <li>
              <strong>Name</strong>: Give the App Registration Name a title.
            </li>
            <li>
              <strong>Supported Account types</strong>: Single Tenant.
            </li>
          </ul>
          <img
            src={appRegistration01}
            alt={appRegistration01}
            style={{ marginTop: "30px", display: "block", width: "100%" }}
          />
          <img
            src={appRegistration02}
            alt={appRegistration02}
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              display: "block",
              width: "100%",
            }}
          />
        </li>
        <li>
          Click <strong>Register</strong>.
        </li>
        <li>
          Once the registration has been completed, the{" "}
          <strong>App Registration</strong> page will load. Within the{" "}
          <strong>Essentials</strong> section, the{" "}
          <strong>Application (client) ID</strong>
          and <strong>Directory (tenant) ID</strong> can be found.
          <img
            src={appRegistration03}
            alt={appRegistration03}
            style={{ marginTop: "30px", marginBottom: "30px", display: "block", width: "100%" }}
          />
        </li>
        <li>
          On the navigation panel on the left, click on{" "}
          <strong>Certificates & Secrets</strong>.
        </li>
        <li>
          Towards the middle-left of the page, click on{" "}
          <strong>+ New Client Secret</strong>. The
          <strong>Add a Client Secret</strong> panel will expand on the right
          side of the browser.
          <img
            src={appRegistration04}
            alt={appRegistration04}
            style={{ marginTop: "30px", display: "block", width: "100%" }}
          />
          <div className={style.note}>
            <strong>Note</strong>
            <p>Expiration Date set to 730 days (24 months)</p>
          </div>
        </li>
        <li style={{marginTop: "30px", marginBottom: "30px"}}>
          Click <strong>Add</strong>. The panel will close, and the Client
          Secret will be in the middle of the page underneath the column
          labelled as <strong>Value</strong>
          <div className={style.note}>
            <strong>Note</strong>
            <p>
              Please note that this value will only appear once, it is
              encouraged to save this value in a secure location should you need
              it a second time. Otherwise, it is best practice to create a whole
              new client secret for each time it is needed.
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
}

export default CreateAppRegistration;
