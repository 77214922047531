import React from "react";

const ConfluenceTableHeader = () => {
  const headers = [
    { name: "Project Name", field: "projectName" }, //ok
    { name: "Confluence page", field: "confluencePageURL" },
    { name: "Execution ID", field: "ID" },
    { name: "Execution Start", field: "startDateTime" }, // ok
    { name: "Duration", field: "duration" }, //ok
    { name: "User", field: "user" }, //ok
    { name: "Execution Status", field: "status" }, //ok
  ];

  return (
    <thead>
      <tr>
        {headers.map(({ name, field }) => (
          <th
            className={`${field === "projectName" ? "" : "text-center"}`}
            key={field}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ConfluenceTableHeader;
