import React, { useState, useEffect } from "react";
import validator from "validator";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { post } from "../../utils/api";
import Modal from "../../components/Modal";

const SnapLoginInfoForm = ({ userInfo, organization }) => {
  const [slServiceEmail, setSlServiceEmail] = useState("");
  const [slServicePassword, setSlServicePassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      if (organization.serviceAccountEmail === "Please Change") {
          setSlServiceEmail("");
          setSlServicePassword("");
          return
        }

        setSlServiceEmail(organization.serviceAccountEmail)
  }, [organization.organization, organization.serviceAccountEmail]);

  const handleEmailChange = (event) => {
    setSlServiceEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setSlServicePassword(event.target.value);
  };

  const onSaveClick = async () => {
    const emailErrorMessage =
      slServiceEmail.trim().length === 0
        ? "Please enter an email address"
        : !validator.isEmail(slServiceEmail)
        ? "Enter a valid Email!"
        : "";

    const passwordErrorMessage =
      slServicePassword.trim().length === 0 ? "Please enter a password" : "";

    setEmailError(emailErrorMessage);
    setPasswordError(passwordErrorMessage);

    try {
      if (!emailErrorMessage && !passwordErrorMessage) {
        setIsSaving(true);

        const response = await post("sl/credentials", {
          slServiceEmail,
          slServicePassword,
          organization: organization.organization,
        });

        if (response.status === 201) {
          toast.success("SL credentials has been changed successfully");
          setSlServicePassword("");
        }
      }
    } catch (error) {
      toast.error(error.response.data.name);
    } finally {
      setIsSaving(false);
    }
  };

  const onDeleteClick = async () => {
    try {
        const response = await post("sl/credentials", {
          slServiceEmail: "Please Change",
          slServicePassword: "Please Change",
          organization: organization.organization,
        });

        if (response.status === 201) {
          setSlServicePassword("");
          setSlServiceEmail("")
          setShowModal(false)
          toast.success("SL credentials has been deleted successfully");
        }
      
    } catch (error) {
      toast.error(error.response.data.name);
    } finally {
      setIsSaving(false);
    }
  };


  if (isSaving) {
    return <Spinner />;
  }

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="cancelButton" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
      <Button id="create-new-organization-summary" onClick={onDeleteClick}>
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      <Row>
        <Col xs={4}>SL Documentation Email</Col>
        <Col xs={8} className="align-items-start">
          <Input
            id="documentationEmail"
            type={slServiceEmail && "email"}
            placeholder="Enter SL Email"
            className="form-control px-4"
            name="slemail"
            value={slServiceEmail}
            onChange={handleEmailChange}
            error={emailError}
            disabled={organization.userRole !== 2}
          />
        </Col>
      </Row>
      {organization.userRole === 2 ? (
        <>
          <Row>
            <Col xs={4}>SL Documentation Password</Col>
            <Col xs={8} className="">
              <Input
                id="documentationPassword"
                type={slServicePassword && "password"}
                placeholder="Enter SL Password"
                className="form-control px-4"
                name="slpassword"
                value={slServicePassword}
                onChange={handlePasswordChange}
                error={passwordError}
              />
            </Col>
          </Row>
          <Row>
            <Col md={11}>
              <div className="d-flex justify-content-end align-items-end mt-3 mb-2">
                <Button onClick={() => setShowModal(true)}>Delete</Button>
              </div>
            </Col>
            <Col md={1}>
              <div className="d-flex justify-content-end align-items-end mt-3 mb-2">
                <Button onClick={onSaveClick}>Save</Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title=""
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>Are you sure you want to delete your SnapLogic organization account from this system ?</div>
      </Modal>
    </>
  );
};

export default SnapLoginInfoForm;
